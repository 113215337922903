
import { computed, defineComponent } from 'vue';

const Question = defineComponent({
    props: {
        saved: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        modelValue: {
            type: Number,
        }
    },
    setup(props, context) {
        const letter = computed(() => ['A', 'B', 'C', 'D', 'E'][props.index]);
        

        const value = computed({
            get() {
                return props.modelValue;
            },
            set(value: any) {
                context.emit("update:modelValue", value);
            }
        });

        const selected = computed(() => props.modelValue == props.index);

        return { letter, value, selected };
    }
});

export default Question;
