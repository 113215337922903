<template>
    <div class="h-screen pt-14 flex items-stretch">

        <!-- Listagem -->
        <div style="width: 415px" class="bg-neutral-3">
            <div class="w-full h-full overflow-hidden flex flex-col items-stretch">

                <!-- Header -->
                <div class="relative h-18 w-full bg-white shadow-md px-7 flex items-center">
                    <img src="@/assets/icons/calendar.svg"/>
                    <div class="flex-1 pl-7">
                        <p class="text-sm leading-5">Provas agendadas</p>
                        <p class="font-bold text-lg leading-5">{{ formattedDate }}</p>
                    </div>
                    <button>
                        <img src="@/assets/icons/search.svg"/>
                    </button>
                </div>

                <!-- Loading -->
                <Transition name="fade" mode="out-in">
                    <div v-if="state.loading" class="flex-1 overflow-x-hidden light-scroll"  :key="1">
                        <AgendamentoCardSkel v-for="n in 8" :key="n"/>
                    </div>
                    <div v-else :key="2" class="flex-1 overflow-x-hidden light-scroll">
                        <AgendamentoEmptyCard v-if="state.schedules.length == 0"/>
                        <AgendamentoCard v-for="(schedule, index) in state.schedules"
                                         :key="index"
                                         :schedule="schedule"
                                         :currentDate="state.currentTime"
                                         :selected="index == state.selectedScheduleIndex"
                                         @pressed="state.selectedScheduleIndex = index"/>
                    </div>
                </Transition>
            </div>
        </div>

        <!-- Conteúdo -->
        <div class="bg-white border-l border-neutral-3 flex flex-col items-center p-10 relative overflow-hidden"
             style="width: calc(100% - 415px)">

            <HomeIllustration class="absolute bottom-0 w-full pointer-events-none" style="min-height: 400px;"/>

            <p class="text-4xl font-bold mb-7">Provas agendadas</p>
               
            <!-- Caso a prova esteja em andamento -->
            <div v-if="selectedSchedule && selectedSchedule.startStatus == 'running'" class="flex flex-col items-center">
                <p>Você tem uma prova em andamento</p>
                
                <!-- Tempo para ingressar  -->
                <div class="flex items-center mb-8">
                    <div class="font-medium mr-1">Tempo para ingressar:</div>
                    <Timer v-model="state.timer" class="font-medium"/>
                </div>
                
                <!-- Iniciar prova -->
                <button class="h-12 w-60 rounded-lg bg-primary flex items-center justify-between px-6" @click="() => selectSchedule()">
                    <div class="text-white font-medium">Iniciar prova</div>
                    <img src="@/assets/icons/arrow_right_white.svg"/>
                </button>
            </div>

            <!-- Caso tenha nenhuma prova selecionada -->
            <p class="text-center" v-else>{{ state.loading ? 'Carregando agendamentos' : `Olá ${user.nome}, ${schedulesLength}`}}</p>                
            
        </div>

        <ConfigureMediaModal />
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, watch,  } from 'vue';
import AgendamentoCard from './AgendamentoCard.vue';
import AgendamentoCardSkel from '@/components/AgendamentoCardSkel.vue';
import AgendamentoEmptyCard from '@/components/AgendamentoEmptyCard.vue';
import HomeIllustration from '@/components/illustrations/HomeIllustration.vue';
import Timer from '@/components/Timer.vue';
import { useAlert } from '@/plugins/alert';

import AgendamentoDTO from '@/DTOs/AgendamentoDTO';
import { addMinutes, differenceInMilliseconds, format, isAfter, isWithinInterval, parse } from 'date-fns';
import UserDTO from '@/DTOs/UserDTO';
import AgendamentoService from '@/services/AgendamentoService';

import vm from '@/viewModels/MainViewModel';
import { useRouter } from 'vue-router';
import { ptBR } from 'date-fns/locale';
import ConfigureMediaModal from '@/components/ConfigureMediaModal.vue';

interface AgendaState {
    date: Date;
    loading: boolean;
    schedules: AgendamentoDTO[];
    selectedScheduleIndex: number;
    timer: number;
    currentTime: Date;
}

const ListagemPage = defineComponent({
    components: {  AgendamentoCard, HomeIllustration, AgendamentoCardSkel, AgendamentoEmptyCard, Timer, ConfigureMediaModal },
    setup() {
        const alert = useAlert();
        const router = useRouter();

        let minuteInterval = -1;

        const state = reactive<AgendaState>({
            date: new Date(),
            loading: true,
            schedules: [],
            selectedScheduleIndex: -1,
            timer: 0,
            currentTime: new Date()
        });

        const selectedSchedule = computed(() => state.schedules[state.selectedScheduleIndex]);

        watch(() => selectedSchedule.value, (newSchedule) => {
            if(newSchedule.startStatus == 'running') {
                state.timer = differenceInMilliseconds(newSchedule.finishTime, new Date());
            }
        });

        const schedulesLength = computed(() => {
            if(state.schedules.length == 0) {
                return 'você não possui provas agendadas para hoje';
            }
            else if(state.schedules.length == 1) {
                return 'você possui uma prova agendada para hoje';
            }
            else {
                return `você possui ${state.schedules.length} provas agendadas para hoje`;
            }
        });

        // Atualizar o tempo
        const setTime = () => {
            const delay = new Date().getSeconds();
            setTimeout(() => {
                state.currentTime = new Date();
                minuteInterval = setInterval(() => {
                    state.currentTime = new Date();
                }, 60000);
            }, 61000 - (delay * 1000));
        };

        const user = computed<UserDTO>(() => vm.user!);

        /** Carregar Agendamentos */
        const loadSchedules = () => {
            state.loading = true;

            const [request] = AgendamentoService.GetAgendamento(user.value.cpf, state.date);
            request
                .then(schedules => {
                    // Injetar as propriedades reativas
                    schedules.forEach((schedule, index) => {
                        const startTime =  parse(schedule.dataAgendamentoProva, "dd/MM/yyyy HH:mm", new Date());
                        startTime.setSeconds(0);
                        
                        Object.defineProperty(schedule, 'startTime', { value: startTime, configurable: true });
                        Object.defineProperty(schedule, 'finishTime', { value: addMinutes(schedule.startTime, schedule.tempoTolerancia), configurable: true });
                        Object.defineProperty(schedule, 'startStatus', {
                            configurable: true,
                            value: computed(() => {
                                if(isAfter(state.currentTime, schedule.finishTime)) {
                                    return 'expired';
                                }
                                else if(isWithinInterval(state.currentTime, { start: schedule.startTime, end: schedule.finishTime })) {
                                    if(state.selectedScheduleIndex == -1) {
                                        state.selectedScheduleIndex = index;
                                    }
                                    return 'running';
                                }
                                else {
                                    return 'awaiting';
                                }
                            })
                        });
                    });
                    state.loading = false;
                    state.schedules = schedules;
                })
                .catch(() => {
                    alert({
                        message: "Não foi possível carregar os agendamentos.",
                        actions: [
                            {
                                title: "Tentar novamente",
                                action: () => loadSchedules(),
                                primary: true
                            },
                            {
                                title: "Cancelar"
                            }
                        ]
                        
                    });
                });
        };

        const selectSchedule = () => {
            vm.selectedSchedule = selectedSchedule.value.id;
            router.push({ name: 'informacoes' });
        };

        const formattedDate = computed(() => {
            return format(new Date(), "dd / MMMM / yyyy", { locale: ptBR });
        });

        
        onMounted(() => {
            loadSchedules();
            setTime();
        });

        onBeforeUnmount(() => clearInterval(minuteInterval));

        return { state, user, selectedSchedule, schedulesLength, selectSchedule, formattedDate };

    }
});

export default ListagemPage;
</script>

<style>

</style>