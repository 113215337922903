<template>
    <Modal :isOpened="state.selectMediaModalOpened" width="540px">
        <div class="bg-white rounded-xl text-center">
            <!-- Header -->
            <div class="p-7 border-b border-neutral-2">
                <p class="font-medium text-xl">Para iniciar configure a câmera e o microfone</p>
            </div>
            <!-- Body -->
            <div class="px-7 border-b border-neutral-2 w-full">
                
                <!-- Camera -->
                <div class="flex justify-between items-sZ border-b border-neutral-2 pt-4 pb-7">
                    <div>
                        <p class="text-left font-medium py-2">Câmera</p>
                        <Camera class="w-56 rounded-xl" :deviceId="state.selectedVideoDevice" @videoStateChanged="videoStateChange"/>
                    </div>
                    <p class="w-52 py-2 text-left">{{ selectedVideoDevice && selectedVideoDevice.label }}</p>
                </div>

                <!-- Microfone -->
                <div class="flex justify-between pt-4 pb-7">
                    <div>
                        <p class="text-left font-medium py-2">Microfone</p>
                        <VolumeMeter :deviceId="state.selectedAudioDevice" @audioStateChanged="audioStateChange"/>
                    </div>
                    <p class="w-52 py-2 text-left">{{ selectedAudioDevice && selectedAudioDevice.label }}</p>
                </div>
            </div>

            <!-- Footer -->
            <div class="flex w-full items-stretch">
                <button class="outline-none h-16 flex items-center justify-center w-full"
                        @click="cancel">
                    Cancelar
                </button>
                <div class="w-px bg-gray-200"></div>
                <button class="outline-none h-16 flex items-center justify-center font-medium w-full disabled:cursor-not-allowed disabled:opacity-50 text-primary"
                        @click="finish"
                        :disabled="!state.hasVideo || !state.hasAudio">
                    Ok
                </button>
            </div>
        </div>
    </Modal>
</template>

<script lang='ts'>
import { computed, defineComponent, reactive } from 'vue';
import Modal from '@/components/Modal.vue';
import VolumeMeter from '@/components/VolumeMeter.vue';
import Camera from '@/components/Camera.vue';

interface ConfigureMediaModalState {
    selectMediaModalOpened: boolean;
    devices: MediaDeviceInfo[];
    selectedVideoDeviceId: string;
    selectedAudioDeviceId: string;
    hasVideo: boolean;
    hasAudio: boolean;
}

const ConfigureMediaModal = defineComponent({
    components: { Modal, VolumeMeter, Camera },
    setup() {
        const state = reactive<ConfigureMediaModalState>({
            selectMediaModalOpened: false,
            devices: [],
            selectedVideoDeviceId: '',
            selectedAudioDeviceId: '',
            hasVideo: false,
            hasAudio: false
        });

        const selectedVideoDevice = computed(() => state.devices.find(el => el.deviceId == state.selectedVideoDeviceId));
        const selectedAudioDevice = computed(() => state.devices.find(el => el.deviceId == state.selectedAudioDeviceId));

        const videoStateChange = (data: { hasVideo: boolean }) => {
            state.hasVideo = data.hasVideo;
        };

        const audioStateChange = (data: { hasAudio: boolean }) => {
            state.hasAudio = data.hasAudio;
        };

        let promiseResolve: ((data: {videoDeviceId: string; audioDeviceId: string }) => void) | null = null;
        let promiseReject: ((error: string) => void) | null = null;

        const configure = (): Promise<{videoDeviceId: string; audioDeviceId: string }> => {
            return new Promise((resolve, reject) => {
                navigator.mediaDevices.enumerateDevices()
                    .then(devices => {
                        state.devices = devices;
                        state.selectedVideoDeviceId = devices.find(el => el.kind == 'videoinput')?.deviceId || '';
                        state.selectedAudioDeviceId = devices.find(el => el.kind == 'audioinput')?.deviceId || '';
                    })
                    .catch(error => {
                        reject(error);
                    });
                
                state.selectMediaModalOpened = true;
                promiseResolve = resolve;
                promiseReject = reject;
            });
        };

        const finish = () => {
            const data = { videoDeviceId: state.selectedVideoDeviceId, audioDeviceId: state.selectedAudioDeviceId };
            state.selectMediaModalOpened = false;
            promiseResolve!(data);
        };

        const cancel = () => {
            state.selectMediaModalOpened = false;
            promiseReject!('Operação cancelada pelo usuário');
        };

        return { state, videoStateChange, audioStateChange, finish, cancel, configure, selectedVideoDevice, selectedAudioDevice };
    }
});

export default ConfigureMediaModal;
</script>

<style>

</style>