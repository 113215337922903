import { Get, Post } from './BaseService';

import LoginDTO from '@/DTOs/LoginDTO';
import UserPostDTO from '@/DTOs/UserPostDTO';
import UserDTO from '@/DTOs/UserDTO';
import { UserQuery } from '@/queryObjets/UserQuery';

export default {
    GetUsers: (query: UserQuery) => {
        return Get<UserDTO[]>("teorico/usuario/api/usuarios", query);
    },
    PostUser: (user: UserPostDTO) => {
        return Post<UserPostDTO, UserPostDTO>("teorico/usuario/api/usuarios", user);
    },
    Login: (data: LoginDTO) => {
        return Post<LoginDTO, UserDTO>('teorico/usuario/api/auth', data);
    }
};