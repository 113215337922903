
import { defineComponent } from 'vue';

const SupportTextModal = defineComponent({
    props: {
        isOpened: {
            type: Boolean,
            default: false
        },
        supportText: {
            type: String
        }
    }
});

export default SupportTextModal;
