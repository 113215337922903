
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, watch,  } from 'vue';
import AgendamentoCard from './AgendamentoCard.vue';
import AgendamentoCardSkel from '@/components/AgendamentoCardSkel.vue';
import AgendamentoEmptyCard from '@/components/AgendamentoEmptyCard.vue';
import HomeIllustration from '@/components/illustrations/HomeIllustration.vue';
import Timer from '@/components/Timer.vue';
import { useAlert } from '@/plugins/alert';

import AgendamentoDTO from '@/DTOs/AgendamentoDTO';
import { addMinutes, differenceInMilliseconds, format, isAfter, isWithinInterval, parse } from 'date-fns';
import UserDTO from '@/DTOs/UserDTO';
import AgendamentoService from '@/services/AgendamentoService';

import vm from '@/viewModels/MainViewModel';
import { useRouter } from 'vue-router';
import { ptBR } from 'date-fns/locale';
import ConfigureMediaModal from '@/components/ConfigureMediaModal.vue';

interface AgendaState {
    date: Date;
    loading: boolean;
    schedules: AgendamentoDTO[];
    selectedScheduleIndex: number;
    timer: number;
    currentTime: Date;
}

const ListagemPage = defineComponent({
    components: {  AgendamentoCard, HomeIllustration, AgendamentoCardSkel, AgendamentoEmptyCard, Timer, ConfigureMediaModal },
    setup() {
        const alert = useAlert();
        const router = useRouter();

        let minuteInterval = -1;

        const state = reactive<AgendaState>({
            date: new Date(),
            loading: true,
            schedules: [],
            selectedScheduleIndex: -1,
            timer: 0,
            currentTime: new Date()
        });

        const selectedSchedule = computed(() => state.schedules[state.selectedScheduleIndex]);

        watch(() => selectedSchedule.value, (newSchedule) => {
            if(newSchedule.startStatus == 'running') {
                state.timer = differenceInMilliseconds(newSchedule.finishTime, new Date());
            }
        });

        const schedulesLength = computed(() => {
            if(state.schedules.length == 0) {
                return 'você não possui provas agendadas para hoje';
            }
            else if(state.schedules.length == 1) {
                return 'você possui uma prova agendada para hoje';
            }
            else {
                return `você possui ${state.schedules.length} provas agendadas para hoje`;
            }
        });

        // Atualizar o tempo
        const setTime = () => {
            const delay = new Date().getSeconds();
            setTimeout(() => {
                state.currentTime = new Date();
                minuteInterval = setInterval(() => {
                    state.currentTime = new Date();
                }, 60000);
            }, 61000 - (delay * 1000));
        };

        const user = computed<UserDTO>(() => vm.user!);

        /** Carregar Agendamentos */
        const loadSchedules = () => {
            state.loading = true;

            const [request] = AgendamentoService.GetAgendamento(user.value.cpf, state.date);
            request
                .then(schedules => {
                    // Injetar as propriedades reativas
                    schedules.forEach((schedule, index) => {
                        const startTime =  parse(schedule.dataAgendamentoProva, "dd/MM/yyyy HH:mm", new Date());
                        startTime.setSeconds(0);
                        
                        Object.defineProperty(schedule, 'startTime', { value: startTime, configurable: true });
                        Object.defineProperty(schedule, 'finishTime', { value: addMinutes(schedule.startTime, schedule.tempoTolerancia), configurable: true });
                        Object.defineProperty(schedule, 'startStatus', {
                            configurable: true,
                            value: computed(() => {
                                if(isAfter(state.currentTime, schedule.finishTime)) {
                                    return 'expired';
                                }
                                else if(isWithinInterval(state.currentTime, { start: schedule.startTime, end: schedule.finishTime })) {
                                    if(state.selectedScheduleIndex == -1) {
                                        state.selectedScheduleIndex = index;
                                    }
                                    return 'running';
                                }
                                else {
                                    return 'awaiting';
                                }
                            })
                        });
                    });
                    state.loading = false;
                    state.schedules = schedules;
                })
                .catch(() => {
                    alert({
                        message: "Não foi possível carregar os agendamentos.",
                        actions: [
                            {
                                title: "Tentar novamente",
                                action: () => loadSchedules(),
                                primary: true
                            },
                            {
                                title: "Cancelar"
                            }
                        ]
                        
                    });
                });
        };

        const selectSchedule = () => {
            vm.selectedSchedule = selectedSchedule.value.id;
            router.push({ name: 'informacoes' });
        };

        const formattedDate = computed(() => {
            return format(new Date(), "dd / MMMM / yyyy", { locale: ptBR });
        });

        
        onMounted(() => {
            loadSchedules();
            setTime();
        });

        onBeforeUnmount(() => clearInterval(minuteInterval));

        return { state, user, selectedSchedule, schedulesLength, selectSchedule, formattedDate };

    }
});

export default ListagemPage;
