<template>
    <div class="w-full h-screen flex flex-col items-center justify-center relative" ref="biometryContentRoot">
        
        <!-- Camera -->
        <div class="w-full h-full">
            <video class="w-full h-full object-cover"
                   style="transform: scale(-1,1)"
                   ref="camera"
                   autoplay playsinline muted/>
            <canvas ref="cameraPlaceholder" style="display: none"></canvas>
        </div>

        <img :src="picture" v-if="picture.length"
             class="w-full h-full absolute object-cover"
             style="transform: scale(-1,1)"/>

        <!-- Mascara -->
        <div class="w-full h-full absolute top-0 right-0 bg-white bg-opacity-90 facial-biometry__mask"></div>

        <!-- Frame -->
        <div class="absolute w-full h-3/5 bg-primary biometry__frame"/>

        <!-- Tituto -->
        <div class="absolute top-0 h-1/6 w-full px-5 text-center flex flex-col items-center justify-center">
            <h1 class="text-3xl font-bold mb-2">{{ title }}</h1>
            <p>{{ subtitle }}</p>
        </div>

        <!-- Options -->
        <div class="absolute bottom-0 h-1/6 flex items-center">

            <transition name="fade" mode="out-in">
                
                <div v-if="picture.length == 0" key="1" class="flex items-center">
                    <!-- Tirar a foto -->
                    <button class="cursor-pointer mx-5 w-14 h-14 rounded-full flex items-center justify-center bg-primary"
                            @click="() => takePicture()">
                        <img src="@/assets/icons/camera.svg"/>
                    </button>
                </div>

                <div v-else-if="!loading" key="2" class="flex items-center">
                    <!-- Confirmar -->
                    <button class="mx-5 w-14 h-14 rounded-full flex items-center justify-center bg-red-500"
                            @click="picture = ''">
                        <img src="@/assets/icons/error.svg" class="w-4"/>
                    </button>
                    <button class="mx-5 w-14 h-14 rounded-full flex items-center justify-center bg-primary" @click="checkBiometry">
                        <img src="@/assets/icons/icon_check.svg" class="w-4"/>
                    </button>
                </div>

                <div v-else key="3">
                    <!-- Carregando -->
                    <div class="mx-5 w-14 h-14 rounded-full flex items-center justify-center bg-primary">
                        <Loading/>
                    </div>
                </div>
            </transition>

        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import Loading from '@/components/Loading.vue';

const FacialBiometry = defineComponent({
    components: { Loading },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Validação facial'
        },
        subtitle: {
            type: String,
            default: 'Encaixe seu rosto no formato e clique no botão abaixo'
        }
    },
    setup(props, context) {
        const camera = ref<HTMLVideoElement | null>(null);
        const cameraPlaceholder = ref<HTMLCanvasElement | null>(null);

        const picture = ref('');
        
        const startCapture = () => {
            const cameraContainer = camera.value as HTMLVideoElement;

            const constraints = { audio: false, video: true };
            navigator.mediaDevices.getUserMedia(constraints)
                .then((media: MediaStream) => {
                    cameraContainer.srcObject = media;
                    cameraContainer.play();
                })
                .catch((e: MediaStreamError) => {
                    console.log(e);
                });
        };

        const takePicture = () => {
            const cameraContainer = camera.value as HTMLVideoElement;
            const canvas = cameraPlaceholder.value as HTMLCanvasElement;
            canvas.width = cameraContainer.videoWidth;
            canvas.height = cameraContainer.videoHeight;

            canvas.getContext('2d')!.drawImage(cameraContainer, 0, 0, cameraContainer.videoWidth, cameraContainer.videoHeight);
            picture.value = canvas!.toDataURL('image/jpg');
        };

        const checkBiometry = () => {
            if(props.loading)
                return;
            context.emit('checkbiometry', { picture: picture.value, reset: () => picture.value = '' } );
        };

        onMounted(() => startCapture());

        onBeforeUnmount(() => {
            const cameraContainer = camera.value as HTMLVideoElement;
            const stream = cameraContainer.srcObject as MediaStream;

            stream.getTracks().forEach(el => {
                el.stop();
            });

            cameraContainer.srcObject = null;
        });

        return { camera, picture, takePicture, cameraPlaceholder, checkBiometry };
    }
});

export default FacialBiometry;
</script>

<style>
.facial-biometry__mask {
     mask-image: url('~@/assets/biometry/square.svg'), url('~@/assets/biometry/face-mask.svg');
    mask-size: cover, auto 60%;
    mask-repeat: no-repeat;
    mask-position: center, center;
    mask-composite: subtract;

    -webkit-mask-image: url('~@/assets/biometry/square.svg'), url('~@/assets/biometry/face-mask.svg');
    -webkit-mask-size: cover, auto 60%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center, center;
    -webkit-mask-composite: source-out;
}
.biometry__frame {
    mask-image: url("~@/assets/biometry/face-frame.svg");
    mask-size: auto 100%;
    mask-position: center;
    mask-repeat: no-repeat;

    -webkit-mask-image: url("~@/assets/biometry/face-frame.svg");
    -webkit-mask-size: auto 100%;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
}
</style>