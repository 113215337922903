<template>
    <div class="flex">
        <div class="h-4 mr-2 rounded-full bg-gray-100"
             v-for="n in 20" :key="n"
             :class="{'bg-primary': intensity / 5 >= n}"
             style="width: 3px" />
        <audio ref="audio" class="hidden"/>
    </div>
</template>

<script lang='ts'>
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
/// @ts-ignore;
import AudioStreamMeter from 'audio-stream-meter';

const VolumeMeter = defineComponent({
    props: {
        deviceId: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const hasAudio = ref(false);
        const intensity = ref(0);
        const audio = ref<HTMLAudioElement | null>(null);

        let meter: any = null;
        const initialize = () => {
            const audioContainer = audio.value as HTMLVideoElement;
            navigator.mediaDevices.getUserMedia({ audio: { deviceId: props.deviceId } })
                .then(stream => {
                    
                    audioContainer.srcObject = stream;
                    const audioContext = new AudioContext();
                    const mediaStream = audioContext.createMediaStreamSource(stream);
 
                    meter = AudioStreamMeter.audioStreamProcessor(audioContext, function() {
                        intensity.value = Math.round(meter.volume * 100);
                        if(meter.volume > 0 && !hasAudio.value) {
                            hasAudio.value = true;
                            context.emit('audioStateChanged', { hasAudio: true });
                        }
                    });
      
                    mediaStream.connect(meter);
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const dispose = () => {
            const audioContainer = audio.value as HTMLVideoElement;
            const stream = audioContainer.srcObject as MediaStream;
            if(meter) {
                meter.close.bind(meter);
            }
            if(stream) {
                stream.getTracks().forEach(el => {
                    el.stop();
                });
            }

            audioContainer.srcObject = null;
        };

        watch(() => props.deviceId, () => {
            dispose();
            initialize();
        });

        onMounted(() => initialize());

        onBeforeUnmount(() => dispose());

        return { audio, intensity, dispose };
    }
});

export default VolumeMeter;
</script>

<style>

</style>