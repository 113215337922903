<template>
    <div class="w-full h-screen bg-neutral-1">
        <Header>
            <button class="text-base bg-transparent font-medium text-primary pointer p-3 mx-7" @click="showFinishAlert">Finalizar exame</button>
        </Header>

        <Transition name="fade" mode="out-in">

            <div v-if="state.loading || state.streamingError" class="h-screen flex items-center justify-center"  :key="1">
                <div class="flex flex-col items-center" v-if="state.streamingError">
                    <p class="font-bold text-3xl mb-2">A conexão está demorando um pouco mais que o esperado</p>
                    <p class="mb-10">Aguarda um pouco enquanto nos reconectamos</p>
                    <Loading color="var(--primary-color)"/>
                </div>
                <div class="flex flex-col items-center" v-else-if="state.loading">
                    <p class="font-bold text-4xl mb-2">Aguarde</p>
                    <p class="mb-10">Estamos preparando seu exame</p>
                    <Loading color="var(--primary-color)"/>
                </div>
            </div>

            <div v-else class="h-screen pt-14 w-full flex items-stretch"  :key="2">
                
                <!-- Conteúdo da questão -->
                <div class="bg-white border-r border-neutral-2 relative pt-28 pb-20 overflow-x-auto light-scroll" style="width: calc(100% - 430px)">
                    
                    <transition name="fade">
                        <OpenTextModalButton class="absolute top-7 right-7" @pressed="state.supportTextModalOpened = true" v-if="supportText"/>
                    </transition>

                    <div class="w-4/6 min-h-full flex flex-col items-stretch justify-between mx-auto">
                        <div>
                            <p class="text-primary text-xs mb-2">{{ currentQuestion.section }}</p>
                            <p class="mb-5 opacity-70">Questão {{ currentQuestionAndTotal }}</p>

                            <div class="mb-4"  :class="[currentQuestion.descricao.length >= 180 ? 'text-xl' : 'text-4xl']" v-html="currentQuestion.descricao"></div>

                            <div class="border-t border-neutral-2 pt-6 mb-5 w-full" v-if="currentQuestion.type == 'discursive'">
                                <textarea class="resize-none w-full bg-neutral-3 rounded-lg p-4"
                                          placeholder="Digite aqui sua resposta" rows="20"
                                          v-model="currentQuestion.discursiveText">
                                </textarea>
                                <p class="opacity-80 text-sm">{{ remainingCharacters > 0 ? `Você possui ${remainingCharacters} caracteres restantes` : 'Você não possui caracteres restantes'}}</p>
                            </div>
                        </div>

                        <div class="opacity-80" v-if="currentQuestion.type == 'objetive'">
                            <p>* Para salvar a resposta, clique em <b>Confirmar.</b></p>
                            <p>* Use as setas para nagevar entra as questões.</p>
                        </div>
                    </div>
                </div>

                <!-- Questões -->
                <div class="p-5" style="width: 430px">
                    <div class="w-full h-full rounded-lg bg-white shadow-2 flex flex-col items-stretch">

                        <!-- Header questoes -->
                        <div class="h-22 flex items-center border-b border-neutral-2 px-8 relative">
                            <img src="@/assets/icons/icon_document_primary.svg"/>
                            <p class="font-medium flex-1 px-4 text-xl">Questão {{ currentQuestionAndTotal }}</p>
                            <Timer v-model="state.timeToFinish" @finished="() => finishExam()" class="text-primary font-bold text-lg"/>
                            
                            <!-- Gabarito -->
                            <div class="absolute left-0 bottom-0 w-full gabarito-container overflow-hidden px-2 whitespace-nowrap flex" ref="gabarito">

                                <!-- Barrinha -->
                                <div  class="flex-1 cursor-pointer" style="min-width: 5%; padding: 0 1.5px;" 
                                      v-for="(questao, index) in exam.questoes" :key="index" @click="state.questionIndex = index"
                                      :id="`question-${index}`">
                                    <div :class="[
                                             {'bg-primary': questao.isSaved && index != state.questionIndex},
                                             {'bg-neutral-black': index == state.questionIndex},
                                             {'bg-neutral-2': !questao.isSaved && index != state.questionIndex}]"
                                         class="w-full rounded-full transition-colors duration-300"
                                         style="height: 3px;"/>
                                </div>
                            </div>
                        </div>

                        <!-- Conteúdo -->
                        <div class="flex-1 overflow-y-auto flex flex-col items-stretch light-scroll">
                            <Question v-for="(alternativa, index) in currentQuestion.alternativas" :key="index"
                                      :title="alternativa.descricao"
                                      :index="index"
                                      :saved="currentQuestion.isSaved"
                                      v-model="currentQuestion.selectedAlternativeIndex"/>

                            <div class="opacity-80 h-full w-full flex flex-col justify-center px-5" v-if="currentQuestion.type == 'discursive'">
                                <p>* Leia o texto de apoio para escrever a redação.</p>
                                <p>* Escreva no bloco do lado esquerdo da tela.</p>
                                <p>* Finalize a redação e confira o seu conteúdo.</p>
                                <p>* Para salvar a resposta, clique em <b>Confirmar.</b></p>
                                <p>* Use as setas para nagevar entra as questões.</p>
                            </div>
                        </div>

                        <!-- Footer -->
                        <div class="flex items-center justify-between p-8 border-t border-neutral-2">
                            <ArrowButton :disabled="state.questionIndex == 0" @pressed="state.questionIndex -=1"/>
                            <transition name="fade" mode="out-in">
                                <button class="h-14 w-40 rounded-lg border border-primary text-primary font-medium"
                                        v-if="currentQuestion.isSaved">
                                    Resposta salva!
                                </button>

                                <button class="h-14 w-40 flex items-center justify-center rounded-lg bg-primary font-medium text-white disabled:bg-neutral-2 disabled:cursor-not-allowed"
                                        v-else
                                        @click="() => saveCurrentQuestion(currentQuestion)"
                                        :disabled="
                                            (currentQuestion.type == 'discursive' && currentQuestion.discursiveText.length == 0) ||  
                                                (currentQuestion.type == 'objective' && currentQuestion.selectedAlternativeIndex == null)">
                                    <Loading v-if="state.savingQuestion"/>
                                    <div v-else style="color: inherit">Confirmar</div>
                                </button>
                            </transition>

                            <ArrowButton direction="right" :disabled="state.questionIndex + 1 == exam.questoes.length" @pressed="state.questionIndex +=1"/>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>

        <!-- Modal de texto de suporte -->
        <SupportTextModal v-model:isOpened="state.supportTextModalOpened" :supportText="supportText"/>

        <!-- Modal finalização do exame -->
        <Transition name="fade">
            <div class="fixed left-0 top-0 w-full h-screen bg-black bg-opacity-60 flex flex-col items-center justify-center z-50" v-if="state.finishing">
                <p class="font-bold text-3xl mb-2 text-white">{{ state.timeToFinish == 0 ? "O tempo para realização da prova acabou" : "Aguarde"}}</p>
                <p class="mb-10 text-white">Estamos finalizando o exame</p>
                <Loading/>
            </div>
        </Transition>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';

// Components
import Header from '@/components/Header.vue';
import Timer from '@/components/Timer.vue';
import ArrowButton from '@/components/ArrowButton.vue';
import Question from '@/components/Question.vue';
import OpenTextModalButton from '@/components/OpenTextModalButton.vue';
import Loading from '@/components/Loading.vue';
import SupportTextModal from '@/components/SupportTextModal.vue';

// DTOs
import GetExameDTO from '@/DTOs/GetExameDTO';
import ExameTeoricoDTO from '@/DTOs/ExameTeoricoDTO';

// Service
import ExameService from '@/services/ExameService';
import { addMinutes, differenceInMilliseconds, isAfter, parse } from 'date-fns';
import { useAlert } from '@/plugins/alert';
import { init, useRecorder } from '@/plugins/recorder';

import QuestaoDTO from '@/DTOs/QuestaoDTO';
import { useRoute, useRouter } from 'vue-router';
import vm from '@/viewModels/MainViewModel';
import { trackers } from '@/plugins/insights';
import { useAzureRecorder } from '@/plugins/recorderAzure';

const ExamePage = defineComponent({
    components: { Header, Timer, ArrowButton, Question, OpenTextModalButton, Loading, SupportTextModal },
    setup(){
        
        const gabarito = ref<HTMLDivElement | null>(null);

        // Hooks
        const alert = useAlert();
        const { publish, unpublish, onLostConnection } = useRecorder();

        const { startCapture, takePicture, stopCapture } = useAzureRecorder();

        const route = useRoute();
        const router = useRouter();

        // Estado
        const state = reactive({
            loading: true,
            streamingError: false,
            questionIndex: 0,
            supportTextModalOpened: false,
            savingQuestion: false,
            finishing: false,
            timeToFinish: 0,
            maxCharacters: 2500
        });
        const exam = ref<ExameTeoricoDTO | null>(null);
        let reconnectTimeout = -1;

        // Variavéis computadas
        const agendamentoId = computed(() => parseInt(route.params.id as string));

        const user = computed(() => vm.user!);

        const currentQuestion = computed(() => exam.value?.questoes[state.questionIndex]);

        const currentQuestionAndTotal = computed(() => {
            return `${(state.questionIndex + 1).toString().padStart(2, '0')} / ${(exam.value?.questoes.length || 1).toString().padStart(2, '0')}`;
        });

        const supportText = computed(() => 
            currentQuestion.value && currentQuestion.value.textoSuporteId ?
             exam.value?.textoSuporte.find(el => el.id == currentQuestion.value!.textoSuporteId)?.texto :
                null);

        const asweredAllQuestions = computed(() => exam.value?.questoes.filter(el => !el.isSaved).length == 0);

        const streamName = computed(() => `EP_${agendamentoId.value}_${user.value?.cpf}`);

        const remainingCharacters = computed(() => currentQuestion.value?.discursiveText != null ? state.maxCharacters - currentQuestion.value.discursiveText.length : 0);
        
        /** Responder Questão */
        const saveCurrentQuestion = (question: QuestaoDTO) => {
            if(((question.type == 'objective' && question.selectedAlternativeIndex != null) || (question.type == 'discursive' && question.discursiveText)) &&
                state.timeToFinish > 0 &&
                !state.savingQuestion) {

                state.savingQuestion = true;

                takePicture()
                    .then(image => {
                        const [request] = ExameService.AswerQuestion(agendamentoId.value, {
                            dataHora: new Date().toISOString(),
                            questaoId: question.id,
                            alternativaEscolhida: question.type == 'objective' ?
                                question.alternativas[question.selectedAlternativeIndex!].id :
                                question.discursiveText!,
                            registroFotoUsuario: image,
                        });

                        return request;
                        
                    })
                    .then(() => {
                        trackers.registerQuestionAswered(user.value.id);
                        question.isSaved = true;
                        if(state.questionIndex + 1 < exam.value!.questoes.length) {
                            state.questionIndex +=1;
                        }
                    })
                    .catch(() => {
                        alert({
                            message: "Não foi possível confirmar a questão, por favor, tente novamente"
                        });
                    })
                    .finally(() => state.savingQuestion = false);
            }
        };

        /** Tempo do exame acabou */
        const timeFinish = () => {
            alert({ 
                title: "Prazo finalizado", message: "O tempo para a realização da prova acabou",
                actions: [{
                    title: "Ok",
                    primary: true,
                    action: () => router.replace({ name: "agenda" })
                }] });
        };

        /** Atualizar o VLibras */
        // const refreshVLibras = () => {
        //     if(vm.vlibras.value) {
        //         setTimeout(() => {
                  
        //             vm.vlibras.value!.refresh();
        //         }, 500);
        //     }
        // };

        /** Carregar Exame */
        const loadExam = () => {
            state.loading = true;
            const examData: GetExameDTO = { clientId: user.value.clientId, usuarioId: user.value.id, agendamentoId: agendamentoId.value };

            // GET no exame
            const [ getExamRequest ] = ExameService.GetExame(examData);
            getExamRequest
                .then(examResp => {
                    // Verificar se está dentro do horário
                    const finalDate = parse(examResp.dataHoraFim, "dd/MM/yyyy HH:mm", new Date());
                    if(isAfter(new Date(), finalDate)) {
                        timeFinish();
                    }
                    else {
                        exam.value = {
                            clienteId: examResp.clienteId,
                            configuracaoId: examResp.configuracaoId,
                            dataHoraFim: examResp.dataHoraFim,
                            dataHoraInicio: examResp.dataHoraInicio,
                            id: examResp.id,
                            statusProva: examResp.statusProva,
                            demo: examResp.demo,
                            textoSuporte: examResp.textoSuporte,
                            usuarioId: examResp.usuarioId,
                            questoes: examResp.questoes.map(exam => ({
                                alternativas: exam.alternativas,
                                descricao: exam.descricao,
                                gabaritoAlternativa: exam.gabaritoAlternativa,
                                id: exam.id,
                                section: exam.section,
                                textoSuporteId: exam.textoSuporteId,
                                type: exam.type,
                                selectedAlternativeIndex: -1,
                                isSaved: false,
                                discursiveText: exam.type == 'discursive' ? '' : undefined
                            }))
                        };

                        // Iniciar o exame
                        return ExameService.StartExam(agendamentoId.value, streamName.value);
                    }
                })
                .then(() => {
                    // Track do Insights
                    trackers.registerExamStarted(user.value.id);

                    // Atualizar tempo restante
                    const finalDate = exam.value!.demo ? addMinutes(new Date(), 5) : parse(exam.value!.dataHoraFim, "dd/MM/yyyy HH:mm", new Date());
                    const beginDate = exam.value!.demo ? new Date() : new Date().setHours(new Date().getHours()+3);

                    state.timeToFinish = differenceInMilliseconds(finalDate, beginDate);
                    // Carregar tela
                    state.loading = false;
                    state.streamingError = false;

                    // refreshVLibras();
                })
                .catch(() => {
                    alert({
                        message: "Não foi possível carregar o exame",
                        actions: [
                            {
                                title: "Voltar",
                                action: () => router.replace({ name: 'agenda' })
                            },
                            {
                                title: "Tentar novamente",
                                action: () => loadExam(),
                                primary: true
                            }
                        ]
                    });
                });
        };

        /** Iniciar captura da Câmera */
        const startCameraCapture = () => {
            startCapture()
                .then(() => loadExam())
                .catch(() => {
                    alert({
                        message: "Não foi possível iniciar a câmera",
                        actions: [
                            {
                                title: "Voltar",
                                action: () => router.replace({ name: 'agenda' })
                            },
                            {
                                title: "Tentar novamente",
                                action: () => startCameraCapture(),
                                primary: true
                            }
                        ]
                    });
                });
        };
        /** Iniciar stream */
        const startStream = () => {
            init(`EP_${agendamentoId.value}_${user.value.cpf}`)
                .then(() => publish())
                .then(() => console.log("Terminou"))
                .catch(() => {
                    reconnectTimeout = setTimeout(() => {
                        startStream();
                    }, 35000);
                });


        };

        /** Usuário perdeu a conexão */
        onLostConnection(() => {
            trackers.registerStreamLog(streamName.value, 'triedReconnect');
            startStream();
        });

        /** Finalizar exame */
        const finishExam = () => {
            if(!state.finishing) {
                state.finishing = true;
                const [request] = ExameService.FinishExam(agendamentoId.value);

                request
                    .then(() => {
                        // Registrar track de finalização
                        trackers.registerExamFinish(user.value.id);

                        setTimeout(() => {
                            state.finishing = false;
                            router.replace({ name: 'finalizacao' });
                        }, 5000);
                    })
                    .catch(error => {
                        state.finishing = false;
                        alert(
                            {
                                message: error,
                                actions: [
                                    {
                                        title: "Tentar novamente",
                                        primary: true,
                                        action: () => finishExam()
                                    }
                                ]
                            }
                        );
                    });
            }
        };

        /** Mostrar alerta de finalizar */
        const showFinishAlert = () => {
            if(!state.loading && !state.savingQuestion && !state.finishing) {
                let message = "Deseja realmente finalizar o exame?";
                if(!asweredAllQuestions.value) {
                    message += ' <br><br><span class="text-red-400">Existem questões não respondidas</span>';
                }
    
                alert({
                    title: "Finalização",
                    message: message,
                    actions: [
                        {
                            title: "Voltar",
                            primary: !asweredAllQuestions.value
                        },
                        {
                            title: "Finalizar",
                            primary: asweredAllQuestions.value,
                            action: () => finishExam()
                        }
                    ]
                });
            }
        };

        onMounted(() => {
            startStream();
            startCameraCapture();
        });
        
        onBeforeUnmount(() => {
            clearTimeout(reconnectTimeout);
            unpublish();
            stopCapture();
        });

        // onUpdated(() => refreshVLibras());

        // ============= Observadores ==============
        /** Verificar o máximo de caracteres */
        watch(() => currentQuestion.value?.discursiveText, newValue => {
            if(newValue && newValue.length >= state.maxCharacters) {
                currentQuestion.value!.discursiveText = currentQuestion.value?.discursiveText?.substr(0, state.maxCharacters);
            }
        });

        /** Fazer o scroll no container de gabarito */
        watch(() => state.questionIndex, (index) => {
            const el = document.querySelector(`#question-${index}`);
            if(el && gabarito.value) {
                gabarito.value.scrollLeft = (el.getBoundingClientRect().width * (index + 1)) - 80;
            }    
        });
        return { state,currentQuestion, currentQuestionAndTotal, supportText, exam, saveCurrentQuestion, showFinishAlert, finishExam, remainingCharacters, gabarito  };
    }
});

export default ExamePage;
</script>

<style>

</style>