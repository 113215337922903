<template>
    <div class="flex flex-col items-end cursor-pointer" @click="$emit('pressed')">
        <TextModalIllustration class="w-32 -mb-7 z-10" />
        <div class="h-11 bg-neutral-3 flex items-center px-3 rounded-md relative">
            <img src="@/assets/icons/icon_document_primary.svg"/>
            <p class="pr-32 pl-3 text-primary font-medium">Abrir texto de apoio</p>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import TextModalIllustration from './illustrations/TextModalIllustration.vue';

const OpenTextModalButton = defineComponent({
    components: { TextModalIllustration }
});

export default OpenTextModalButton;
</script>

<style>

</style>