<template>
    <div class="h-screen flex items-center relative">

        <!-- Conteúdo -->
        <div class="w-2/5 mx-32 max-w-screen-sm">
            <p class="text-xl font-medium text-primary mb-6">Seu exame foi concluído</p>
            <p class="text-4xl font-bold mb-6">Seu resultado será divulgado em breve.</p>
            <p class="opacity-80 mb-20">Sua prova foi enviada para análise, aguarde o resultado. Você pode visualizar o status do exame na seção <b>Provas realizadas.</b></p>

            <button class="h-14 w-80 border border-primary rounded-lg text-primary font-medium" @click="$router.replace({ name: 'agenda'})">
                Início
            </button>
        </div>

        <!-- Ilustração -->
        <div class="flex-1 flex justify-center">
            <FinishIllustration class="w-full" style="max-width: 26rem"/>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import FinishIllustration from '@/components/illustrations/FinishIllustration.vue';

const ExameFinalizadoPage = defineComponent({
    components: { FinishIllustration }
});

export default ExameFinalizadoPage;
</script>

<style>

</style>