
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';

const Camera = defineComponent({
    props: {
        deviceId: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const camera = ref<HTMLVideoElement | null>(null);

        const initialize = () => {
                
            const cameraContainer = camera.value as HTMLVideoElement;
    
            const constraints: MediaStreamConstraints = { 
                audio: false,
                video: { deviceId: props.deviceId }
            };

            navigator.mediaDevices.getUserMedia(constraints)
                .then((media: MediaStream) => {
                    cameraContainer.srcObject = media;
                    cameraContainer.play();
                    context.emit('videoStateChanged', { hasVideo: true });
                })
                .catch((e: MediaStreamError) => {
                    console.log(e);
                });
        };

        const dispose = () => {
            const cameraContainer = camera.value as HTMLVideoElement;
            const stream = cameraContainer.srcObject as MediaStream;

            stream.getTracks().forEach(el => {
                el.stop();
            });

            cameraContainer.srcObject = null;
        };

        onMounted(() => initialize());

        onBeforeUnmount(() => dispose());

        return { camera };
    }
});

export default Camera;
