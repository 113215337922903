
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';

// Components
import Header from '@/components/Header.vue';
import Timer from '@/components/Timer.vue';
import ArrowButton from '@/components/ArrowButton.vue';
import Question from '@/components/Question.vue';
import OpenTextModalButton from '@/components/OpenTextModalButton.vue';
import Loading from '@/components/Loading.vue';
import SupportTextModal from '@/components/SupportTextModal.vue';

// DTOs
import GetExameDTO from '@/DTOs/GetExameDTO';
import ExameTeoricoDTO from '@/DTOs/ExameTeoricoDTO';

// Service
import ExameService from '@/services/ExameService';
import { addMinutes, differenceInMilliseconds, isAfter, parse } from 'date-fns';
import { useAlert } from '@/plugins/alert';
import { init, useRecorder } from '@/plugins/recorder';

import QuestaoDTO from '@/DTOs/QuestaoDTO';
import { useRoute, useRouter } from 'vue-router';
import vm from '@/viewModels/MainViewModel';
import { trackers } from '@/plugins/insights';
import { useAzureRecorder } from '@/plugins/recorderAzure';

const ExamePage = defineComponent({
    components: { Header, Timer, ArrowButton, Question, OpenTextModalButton, Loading, SupportTextModal },
    setup(){
        
        const gabarito = ref<HTMLDivElement | null>(null);

        // Hooks
        const alert = useAlert();
        const { publish, unpublish, onLostConnection } = useRecorder();

        const { startCapture, takePicture, stopCapture } = useAzureRecorder();

        const route = useRoute();
        const router = useRouter();

        // Estado
        const state = reactive({
            loading: true,
            streamingError: false,
            questionIndex: 0,
            supportTextModalOpened: false,
            savingQuestion: false,
            finishing: false,
            timeToFinish: 0,
            maxCharacters: 2500
        });
        const exam = ref<ExameTeoricoDTO | null>(null);
        let reconnectTimeout = -1;

        // Variavéis computadas
        const agendamentoId = computed(() => parseInt(route.params.id as string));

        const user = computed(() => vm.user!);

        const currentQuestion = computed(() => exam.value?.questoes[state.questionIndex]);

        const currentQuestionAndTotal = computed(() => {
            return `${(state.questionIndex + 1).toString().padStart(2, '0')} / ${(exam.value?.questoes.length || 1).toString().padStart(2, '0')}`;
        });

        const supportText = computed(() => 
            currentQuestion.value && currentQuestion.value.textoSuporteId ?
             exam.value?.textoSuporte.find(el => el.id == currentQuestion.value!.textoSuporteId)?.texto :
                null);

        const asweredAllQuestions = computed(() => exam.value?.questoes.filter(el => !el.isSaved).length == 0);

        const streamName = computed(() => `EP_${agendamentoId.value}_${user.value?.cpf}`);

        const remainingCharacters = computed(() => currentQuestion.value?.discursiveText != null ? state.maxCharacters - currentQuestion.value.discursiveText.length : 0);
        
        /** Responder Questão */
        const saveCurrentQuestion = (question: QuestaoDTO) => {
            if(((question.type == 'objective' && question.selectedAlternativeIndex != null) || (question.type == 'discursive' && question.discursiveText)) &&
                state.timeToFinish > 0 &&
                !state.savingQuestion) {

                state.savingQuestion = true;

                takePicture()
                    .then(image => {
                        const [request] = ExameService.AswerQuestion(agendamentoId.value, {
                            dataHora: new Date().toISOString(),
                            questaoId: question.id,
                            alternativaEscolhida: question.type == 'objective' ?
                                question.alternativas[question.selectedAlternativeIndex!].id :
                                question.discursiveText!,
                            registroFotoUsuario: image,
                        });

                        return request;
                        
                    })
                    .then(() => {
                        trackers.registerQuestionAswered(user.value.id);
                        question.isSaved = true;
                        if(state.questionIndex + 1 < exam.value!.questoes.length) {
                            state.questionIndex +=1;
                        }
                    })
                    .catch(() => {
                        alert({
                            message: "Não foi possível confirmar a questão, por favor, tente novamente"
                        });
                    })
                    .finally(() => state.savingQuestion = false);
            }
        };

        /** Tempo do exame acabou */
        const timeFinish = () => {
            alert({ 
                title: "Prazo finalizado", message: "O tempo para a realização da prova acabou",
                actions: [{
                    title: "Ok",
                    primary: true,
                    action: () => router.replace({ name: "agenda" })
                }] });
        };

        /** Atualizar o VLibras */
        // const refreshVLibras = () => {
        //     if(vm.vlibras.value) {
        //         setTimeout(() => {
                  
        //             vm.vlibras.value!.refresh();
        //         }, 500);
        //     }
        // };

        /** Carregar Exame */
        const loadExam = () => {
            state.loading = true;
            const examData: GetExameDTO = { clientId: user.value.clientId, usuarioId: user.value.id, agendamentoId: agendamentoId.value };

            // GET no exame
            const [ getExamRequest ] = ExameService.GetExame(examData);
            getExamRequest
                .then(examResp => {
                    // Verificar se está dentro do horário
                    const finalDate = parse(examResp.dataHoraFim, "dd/MM/yyyy HH:mm", new Date());
                    if(isAfter(new Date(), finalDate)) {
                        timeFinish();
                    }
                    else {
                        exam.value = {
                            clienteId: examResp.clienteId,
                            configuracaoId: examResp.configuracaoId,
                            dataHoraFim: examResp.dataHoraFim,
                            dataHoraInicio: examResp.dataHoraInicio,
                            id: examResp.id,
                            statusProva: examResp.statusProva,
                            demo: examResp.demo,
                            textoSuporte: examResp.textoSuporte,
                            usuarioId: examResp.usuarioId,
                            questoes: examResp.questoes.map(exam => ({
                                alternativas: exam.alternativas,
                                descricao: exam.descricao,
                                gabaritoAlternativa: exam.gabaritoAlternativa,
                                id: exam.id,
                                section: exam.section,
                                textoSuporteId: exam.textoSuporteId,
                                type: exam.type,
                                selectedAlternativeIndex: -1,
                                isSaved: false,
                                discursiveText: exam.type == 'discursive' ? '' : undefined
                            }))
                        };

                        // Iniciar o exame
                        return ExameService.StartExam(agendamentoId.value, streamName.value);
                    }
                })
                .then(() => {
                    // Track do Insights
                    trackers.registerExamStarted(user.value.id);

                    // Atualizar tempo restante
                    const finalDate = exam.value!.demo ? addMinutes(new Date(), 5) : parse(exam.value!.dataHoraFim, "dd/MM/yyyy HH:mm", new Date());
                    const beginDate = exam.value!.demo ? new Date() : new Date().setHours(new Date().getHours()+3);

                    state.timeToFinish = differenceInMilliseconds(finalDate, beginDate);
                    // Carregar tela
                    state.loading = false;
                    state.streamingError = false;

                    // refreshVLibras();
                })
                .catch(() => {
                    alert({
                        message: "Não foi possível carregar o exame",
                        actions: [
                            {
                                title: "Voltar",
                                action: () => router.replace({ name: 'agenda' })
                            },
                            {
                                title: "Tentar novamente",
                                action: () => loadExam(),
                                primary: true
                            }
                        ]
                    });
                });
        };

        /** Iniciar captura da Câmera */
        const startCameraCapture = () => {
            startCapture()
                .then(() => loadExam())
                .catch(() => {
                    alert({
                        message: "Não foi possível iniciar a câmera",
                        actions: [
                            {
                                title: "Voltar",
                                action: () => router.replace({ name: 'agenda' })
                            },
                            {
                                title: "Tentar novamente",
                                action: () => startCameraCapture(),
                                primary: true
                            }
                        ]
                    });
                });
        };
        /** Iniciar stream */
        const startStream = () => {
            init(`EP_${agendamentoId.value}_${user.value.cpf}`)
                .then(() => publish())
                .then(() => console.log("Terminou"))
                .catch(() => {
                    reconnectTimeout = setTimeout(() => {
                        startStream();
                    }, 35000);
                });


        };

        /** Usuário perdeu a conexão */
        onLostConnection(() => {
            trackers.registerStreamLog(streamName.value, 'triedReconnect');
            startStream();
        });

        /** Finalizar exame */
        const finishExam = () => {
            if(!state.finishing) {
                state.finishing = true;
                const [request] = ExameService.FinishExam(agendamentoId.value);

                request
                    .then(() => {
                        // Registrar track de finalização
                        trackers.registerExamFinish(user.value.id);

                        setTimeout(() => {
                            state.finishing = false;
                            router.replace({ name: 'finalizacao' });
                        }, 5000);
                    })
                    .catch(error => {
                        state.finishing = false;
                        alert(
                            {
                                message: error,
                                actions: [
                                    {
                                        title: "Tentar novamente",
                                        primary: true,
                                        action: () => finishExam()
                                    }
                                ]
                            }
                        );
                    });
            }
        };

        /** Mostrar alerta de finalizar */
        const showFinishAlert = () => {
            if(!state.loading && !state.savingQuestion && !state.finishing) {
                let message = "Deseja realmente finalizar o exame?";
                if(!asweredAllQuestions.value) {
                    message += ' <br><br><span class="text-red-400">Existem questões não respondidas</span>';
                }
    
                alert({
                    title: "Finalização",
                    message: message,
                    actions: [
                        {
                            title: "Voltar",
                            primary: !asweredAllQuestions.value
                        },
                        {
                            title: "Finalizar",
                            primary: asweredAllQuestions.value,
                            action: () => finishExam()
                        }
                    ]
                });
            }
        };

        onMounted(() => {
            startStream();
            startCameraCapture();
        });
        
        onBeforeUnmount(() => {
            clearTimeout(reconnectTimeout);
            unpublish();
            stopCapture();
        });

        // onUpdated(() => refreshVLibras());

        // ============= Observadores ==============
        /** Verificar o máximo de caracteres */
        watch(() => currentQuestion.value?.discursiveText, newValue => {
            if(newValue && newValue.length >= state.maxCharacters) {
                currentQuestion.value!.discursiveText = currentQuestion.value?.discursiveText?.substr(0, state.maxCharacters);
            }
        });

        /** Fazer o scroll no container de gabarito */
        watch(() => state.questionIndex, (index) => {
            const el = document.querySelector(`#question-${index}`);
            if(el && gabarito.value) {
                gabarito.value.scrollLeft = (el.getBoundingClientRect().width * (index + 1)) - 80;
            }    
        });
        return { state,currentQuestion, currentQuestionAndTotal, supportText, exam, saveCurrentQuestion, showFinishAlert, finishExam, remainingCharacters, gabarito  };
    }
});

export default ExamePage;
