/* eslint-disable @typescript-eslint/ban-ts-ignore */
/// @ts-ignore
import { WebRTCAdaptor } from './antmedia/webrtc_adaptor';
import { trackers } from '@/plugins/insights';

type SuccessCallbacks = 'initialized' | 'publish_started' | 'publish_finished' | 'screen_share_extension_available' | 'screen_share_stopped' | 'closed' | 'pong' | 'refreshConnection' | 'ice_connection_state_changed' | 'updated_stats' | 'browser_screen_share_supported' | "error";

// Configurações
const pc_config = {
    iceServers : [
        {
            urls : 'stun:stun4.l.google.com:19302'
        }
    ]
};
const location = {
    hostname: "antmedia-vsoft.brazilsouth.cloudapp.azure.com",
    port: 5443
};
const sdpConstraints = {
    OfferToReceiveAudio : false,
    OfferToReceiveVideo : false 
};

const mediaConstraints = {
    video : true,
    audio : true
};

// ==============
let lostConnectionAction: (() => void) | null = null;

let currentStreamId: string | null = null;
let webRTCAdaptor: WebRTCAdaptor;
let listeners: { key: SuccessCallbacks; callBack: () => void }[] = [];

const dispose = () => {
    listeners = [];
    currentStreamId = null;
    const videoContainer = document.querySelector("#stream-container");
    if(videoContainer) {
        document.body.removeChild(videoContainer as Node);
    }
    
    if(webRTCAdaptor != null) {
        webRTCAdaptor.closeStream();
        webRTCAdaptor.closeWebSocket();
        webRTCAdaptor = null;
    }
};

const init = (streamId: string): Promise<void> => {
    console.log("%cChamou o método Init", "color: blue");
    currentStreamId = streamId;

    return new Promise((resolve, reject) => {
        // Criar instancia da tag video
        let video = document.querySelector("#stream-container") as HTMLVideoElement;

        if(!video) {
            video = document.createElement("video");
            video.setAttribute("id", "stream-container");
            video.style.display = 'none';
            document.body.appendChild(video);
        }

        webRTCAdaptor = new WebRTCAdaptor({
            websocket_url : "wss://" + location.hostname +":"+ location.port+"/WebRTCAppEE/websocket",
            mediaConstraints : mediaConstraints,
            peerconnection_config : pc_config,
            sdp_constraints : sdpConstraints,
            localVideoId : "stream-container",
            debug: false,
            callback : function(info: SuccessCallbacks) {
                // Biblioteca iniciada
                if(info == "initialized") {
                    resolve();
                }

                // Fechar a Stream caso finalize a conexão
                if(info == 'closed') {
                    if(currentStreamId) {
                        console.log("%cConexão finalizada", 'color: purple');
                    }
                }

                 // Executar os callbacks
                 listeners.find(el => el.key == info)?.callBack();
                 
            },
            callbackError : function(error: any) {
                trackers.registerStreamLog(currentStreamId!, 'initFailed');
                listeners.find(el => el.key == 'error')?.callBack();
                
                dispose();
                console.log("%cNão foi possível se conectar", 'color: red');
                console.log(JSON.stringify(error));
                reject("Não foi possível inicializar a biblioteca");
            }
        });
    });
};

const publish = (): Promise<void> => {
    // Registrar evento de tentativa de iniciar
    trackers.registerStreamLog(currentStreamId, 'tryPublish');
    
    console.log("%cChamou o método Start", "color: orange");
    return new Promise((resolve, reject) => {
        listeners = [];
    
        // PUBLISHED
        listeners.push({ key: "publish_started", callBack: () => {

            // Track evento publicado
            trackers.registerStreamLog(currentStreamId, 'published');

            console.log("%cPublicado", 'color: green');

            // Adicionar listener para caso perca a conexão
            listeners.push({ key: "closed", callBack: () => {
                trackers.registerStreamLog(currentStreamId, 'lostConnection');
                dispose();
                lostConnectionAction && lostConnectionAction();
            } });

            resolve();
        } });

        // Adicionar lister para erro
        listeners.push({ key: "error", callBack: () => {
            // Track falha na conexão
            trackers.registerStreamLog(currentStreamId, 'connectionFailed');
            reject("Não foi possível inicializar o stream");
        } });

        // Realizar o publish
        webRTCAdaptor.publish(currentStreamId);  
    });
};

// PARA O STREAM
const unpublish = () => {
    trackers.registerStreamLog(currentStreamId!, 'stoped');
    console.log("%cChamou o método stop", "color: red");
    dispose();
};

const onLostConnection = (callback: () => void) => {
    lostConnectionAction = callback;
};

const useRecorder = () => ({
    publish,
    unpublish,
    onLostConnection,
});

export { useRecorder, init,  };



