import AgendamentoDTO from '@/DTOs/AgendamentoDTO';
import { format } from 'date-fns';
import { Get } from "./BaseService";

const AgendamentoService = {
    GetAgendamento: (userCpf: string, date?: Date) => {
        const dateString = format(date || new Date(), 'yyyy-MM-dd');
        return Get<AgendamentoDTO[]>(`easy-procto/agendamento/api/agendamentos/usuario/${userCpf}`, { date: dateString });
    }
};

export default AgendamentoService;