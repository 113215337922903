const startCapture = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        // Criar instancia da tag video
        let video = document.querySelector("#recorder-container") as HTMLVideoElement;
        let canvas = document.querySelector("#recorder-canvas") as HTMLCanvasElement;
        if(!video) {
            video = document.createElement("video");
            video.setAttribute("id", "recorder-container");
            video.style.display = 'none';
            document.body.appendChild(video);
        }
    
        if(!canvas) {
            canvas = document.createElement("canvas");
            canvas.setAttribute("id", 'recorder-canvas');
            canvas.style.display = 'none';
            document.body.appendChild(canvas);
        }
    
        const constraints = { audio: false, video: true };
        navigator.mediaDevices.getUserMedia(constraints)
            .then((media: MediaStream) => {
                video.srcObject = media;
                video.play();
                resolve();
            })
            .catch((e: MediaStreamError) => {
                reject(e);
            });
    });
};

const takePicture = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const video = document.querySelector("#recorder-container") as HTMLVideoElement;
        const canvas = document.querySelector("#recorder-canvas") as HTMLCanvasElement;
        if(video && canvas) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        
            canvas.getContext('2d')!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            const picture = canvas!.toDataURL('image/jpg');
            
            resolve(picture);
        }
        else {
            reject("A câmera ainda não foi iniciada");
        }
    });
};

const stopCapture = () => {
    const cameraContainer = document.querySelector("#recorder-container") as HTMLVideoElement;
    const stream = cameraContainer.srcObject as MediaStream;

    stream.getTracks().forEach(el => {
        el.stop();
    });

    cameraContainer.srcObject = null;
};

export const useAzureRecorder = () => ({
    startCapture,
    stopCapture,
    takePicture
});