
import { defineComponent } from 'vue';
import InformacoesIllustration from '@/components/illustrations/InformacoesIllustration.vue';
import ConfigureMediaModal from '@/components/ConfigureMediaModal.vue';

import { useRouter } from 'vue-router';

const InformacoesPage = defineComponent({
    components: { InformacoesIllustration, ConfigureMediaModal },
    setup() {

        const router = useRouter();

        const startExam = () => {
            router.push({ name: 'biometry' });
        };
        return { startExam };
    }
});

export default InformacoesPage;
