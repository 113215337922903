<template>
    <transition name="fade">
        <div class="fixed w-full h-full bg-black bg-opacity-60 z-20 top-0 right-0 flex items-center justify-center" v-if="isOpened && supportText">
            <transition name="modalAppear" appear>
                <div class="w-4/5 max-w-screen-lg h-5/6 bg-white rounded-xl flex flex-col items-stretch overflow-hidden">

                    <!-- header -->
                    <div class="font-bold h-14 border-b border-neutral-2 flex items-center justify-center text-lg">
                        Texto de Apoio
                    </div>

                    <!-- Conteúdo -->
                    <div class="flex-1 overflow-auto p-6" v-html="supportText">
                    </div>

                    <!-- Footer -->
                    <div class="h-22 shadow-lg flex items-center justify-center border-t border-neutral-2">
                        <button class="btn-primary w-80" @click="$emit('update:isOpened', false)">Fechar</button>
                    </div>
                </div>

            </transition>
        </div>

    </transition>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const SupportTextModal = defineComponent({
    props: {
        isOpened: {
            type: Boolean,
            default: false
        },
        supportText: {
            type: String
        }
    }
});

export default SupportTextModal;
</script>

<style>

</style>