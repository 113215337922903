
import AgendamentoDTO from '@/DTOs/AgendamentoDTO';
import { computed, defineComponent } from 'vue';
import { PropType } from 'vue';
import { format } from 'date-fns';

const AgendamentoCard = defineComponent({
    props: {
        schedule: {
            type: Object as PropType<AgendamentoDTO>,
            required: true
        },
        currentDate: {
            type: Date as PropType<Date>,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const message = computed<string>(() => {
            switch (props.schedule.startStatus) {
                case 'expired':
                    return 'Tempo de tolerância esgotado';
                case 'running':
                    return 'Prova em andamento';
                default:
                    return `Entrada: ${format(props.schedule.startTime, 'HH:mm')}-${format(props.schedule.finishTime, 'HH:mm')}`;
            }
        });

        return { status, message };
    }
});

export default AgendamentoCard;
