
import { computed, defineComponent, reactive } from 'vue';
import Modal from '@/components/Modal.vue';
import VolumeMeter from '@/components/VolumeMeter.vue';
import Camera from '@/components/Camera.vue';

interface ConfigureMediaModalState {
    selectMediaModalOpened: boolean;
    devices: MediaDeviceInfo[];
    selectedVideoDeviceId: string;
    selectedAudioDeviceId: string;
    hasVideo: boolean;
    hasAudio: boolean;
}

const ConfigureMediaModal = defineComponent({
    components: { Modal, VolumeMeter, Camera },
    setup() {
        const state = reactive<ConfigureMediaModalState>({
            selectMediaModalOpened: false,
            devices: [],
            selectedVideoDeviceId: '',
            selectedAudioDeviceId: '',
            hasVideo: false,
            hasAudio: false
        });

        const selectedVideoDevice = computed(() => state.devices.find(el => el.deviceId == state.selectedVideoDeviceId));
        const selectedAudioDevice = computed(() => state.devices.find(el => el.deviceId == state.selectedAudioDeviceId));

        const videoStateChange = (data: { hasVideo: boolean }) => {
            state.hasVideo = data.hasVideo;
        };

        const audioStateChange = (data: { hasAudio: boolean }) => {
            state.hasAudio = data.hasAudio;
        };

        let promiseResolve: ((data: {videoDeviceId: string; audioDeviceId: string }) => void) | null = null;
        let promiseReject: ((error: string) => void) | null = null;

        const configure = (): Promise<{videoDeviceId: string; audioDeviceId: string }> => {
            return new Promise((resolve, reject) => {
                navigator.mediaDevices.enumerateDevices()
                    .then(devices => {
                        state.devices = devices;
                        state.selectedVideoDeviceId = devices.find(el => el.kind == 'videoinput')?.deviceId || '';
                        state.selectedAudioDeviceId = devices.find(el => el.kind == 'audioinput')?.deviceId || '';
                    })
                    .catch(error => {
                        reject(error);
                    });
                
                state.selectMediaModalOpened = true;
                promiseResolve = resolve;
                promiseReject = reject;
            });
        };

        const finish = () => {
            const data = { videoDeviceId: state.selectedVideoDeviceId, audioDeviceId: state.selectedAudioDeviceId };
            state.selectMediaModalOpened = false;
            promiseResolve!(data);
        };

        const cancel = () => {
            state.selectMediaModalOpened = false;
            promiseReject!('Operação cancelada pelo usuário');
        };

        return { state, videoStateChange, audioStateChange, finish, cancel, configure, selectedVideoDevice, selectedAudioDevice };
    }
});

export default ConfigureMediaModal;
