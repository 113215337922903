
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import Loading from '@/components/Loading.vue';

const FacialBiometry = defineComponent({
    components: { Loading },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Validação facial'
        },
        subtitle: {
            type: String,
            default: 'Encaixe seu rosto no formato e clique no botão abaixo'
        }
    },
    setup(props, context) {
        const camera = ref<HTMLVideoElement | null>(null);
        const cameraPlaceholder = ref<HTMLCanvasElement | null>(null);

        const picture = ref('');
        
        const startCapture = () => {
            const cameraContainer = camera.value as HTMLVideoElement;

            const constraints = { audio: false, video: true };
            navigator.mediaDevices.getUserMedia(constraints)
                .then((media: MediaStream) => {
                    cameraContainer.srcObject = media;
                    cameraContainer.play();
                })
                .catch((e: MediaStreamError) => {
                    console.log(e);
                });
        };

        const takePicture = () => {
            const cameraContainer = camera.value as HTMLVideoElement;
            const canvas = cameraPlaceholder.value as HTMLCanvasElement;
            canvas.width = cameraContainer.videoWidth;
            canvas.height = cameraContainer.videoHeight;

            canvas.getContext('2d')!.drawImage(cameraContainer, 0, 0, cameraContainer.videoWidth, cameraContainer.videoHeight);
            picture.value = canvas!.toDataURL('image/jpg');
        };

        const checkBiometry = () => {
            if(props.loading)
                return;
            context.emit('checkbiometry', { picture: picture.value, reset: () => picture.value = '' } );
        };

        onMounted(() => startCapture());

        onBeforeUnmount(() => {
            const cameraContainer = camera.value as HTMLVideoElement;
            const stream = cameraContainer.srcObject as MediaStream;

            stream.getTracks().forEach(el => {
                el.stop();
            });

            cameraContainer.srcObject = null;
        });

        return { camera, picture, takePicture, cameraPlaceholder, checkBiometry };
    }
});

export default FacialBiometry;
