<template>
    <div class="h-screen">
        <!-- Header -->
        <Header>
            <p>{{ vm.user && vm.user.nome }}</p>
            <div class="bg-center bg-cover bg-no-repeat w-8 h-8 rounded-full bg-neutral-2 mx-6" style="background-image: url(https://goodshepherdirvine.com/wp-content/uploads/2017/08/user-placeholder.jpg)"></div>
            <div class="h-6 w-px bg-neutral-2"/>
            <button class="h-14 px-8" @click="() => logout()">
                <img src="@/assets/icons/logout.svg" class="w-4"/>
            </button>
        </Header>
        <RouterView #default="{ Component }">
            <transition name="fade" mode="out-in">
                <component :is="Component"/>
            </transition>
        </RouterView>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import { useAlert } from '@/plugins/alert';
import vm from '@/viewModels/MainViewModel';
import { useRouter } from 'vue-router';

//  Components
import Header from '@/components/Header.vue';

const CandidatoMainPage = defineComponent({
    components: { Header },
    setup() {

        const alert = useAlert();
        const router = useRouter();

        /** Logout */
        const logout = () => {
            alert({
                icon: 'logout',
                title: 'Logout',
                message: 'Tem certeza que deseja sair do sistema?',
                actions: [
                    {
                        title: 'Cancelar',
                        primary: true
                    },
                    {
                        title: 'Sair',
                        action: () => {
                            vm.logout();
                            router.replace({ name: 'login-candidato' });
                        }
                    }
                ]
            });
        };

        return { logout, vm };
    }
});

export default CandidatoMainPage;
</script>

<style>
</style>