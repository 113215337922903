<template>
    <svg preserveAspectRatio="xMidYMin slice" viewBox="0 0 1280 467" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="69" width="1280" height="398">
            <rect y="69" width="1280" height="398" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask0)">
            <path opacity="0.6" d="M669 266.358C448.2 384.758 129.333 379.025 -2.5 361.358V501.858H1307.5C1310.67 450.358 1315.1 340.758 1307.5 314.358C1299.9 287.958 1270.5 264.23 1215 239.358C1125 199.025 889.8 147.958 669 266.358Z" fill="#F8F8F8"/>
            <path opacity="0.6" d="M659 291.358C438.2 409.758 119.333 404.025 -12.5 386.358V526.858H1297.5C1300.67 475.358 1305.1 365.758 1297.5 339.358C1289.9 312.958 1260.5 289.23 1205 264.358C1115 224.025 879.8 172.958 659 291.358Z" fill="#F4F4F4"/>
            <path d="M464.5 402.5C261 440.5 49.1667 433 -1 429.5V537.5L1247 528C1298.67 486.167 1432.9 450.5 1374.5 440.5C1316.1 430.5 1398.5 378.5 1277.5 378.5C996 339 774.173 344.674 464.5 402.5Z" fill="#04986D"/>
        </g>
        <path d="M981.2 190.184L956.316 274.129C955.18 277.846 951.773 280.427 947.849 280.427L846.971 280.737C841.602 280.737 837.369 276.297 837.679 270.928C837.988 265.972 842.015 262.048 846.971 262.048H885.588L916.46 175.935H970.461C977.896 175.935 983.265 183.06 981.2 190.184Z" fill="#455A64"/>
        <path d="M918.732 269.276C906.015 269.276 895.706 269.137 895.706 268.966C895.706 268.795 906.015 268.656 918.732 268.656C931.448 268.656 941.757 268.795 941.757 268.966C941.757 269.137 931.448 269.276 918.732 269.276Z" fill="#263238"/>
        <path d="M973.764 178.62C973.558 178.517 966.743 198.651 958.38 223.535C950.12 248.419 943.511 268.553 943.718 268.656C943.924 268.76 950.739 248.625 959.103 223.741C967.363 198.858 973.868 178.723 973.764 178.62Z" fill="#263238"/>
        <path d="M899.94 354.976L906.135 354.872V280.427H899.94V354.976Z" fill="#455A64"/>
        <path d="M931.638 354.666H874.436L900.352 345.683H906.547L931.638 354.666Z" fill="#455A64"/>
        <path d="M946.196 156.73L936.697 204.02L938.349 239.436L874.642 228.801L877.12 197.722V172.012L861.632 202.368L849.449 178.93L871.751 138.661C875.778 134.428 880.631 131.33 886.31 129.265C889.098 128.233 891.989 127.613 895.396 127.51C902.727 129.782 910.058 129.782 917.389 127.51C918.318 127.613 919.557 127.716 921.106 127.923C921.209 127.923 921.312 127.923 921.416 127.923C934.942 129.575 945.267 140.726 945.99 154.356L946.196 156.73Z" fill="#857D8E"/>
        <path d="M921.21 127.82C921.21 127.82 947.333 128.026 950.637 155.595L951.05 175.832L928.541 175.626L921.21 127.82Z" fill="#857D8E"/>
        <path d="M858.639 163.442C858.639 163.648 879.187 163.752 904.587 163.752C929.987 163.752 950.534 163.545 950.534 163.442C950.534 163.235 929.987 163.132 904.587 163.132C879.29 163.029 858.639 163.235 858.639 163.442Z" fill="#FAFAFA"/>
        <path d="M858.639 167.779C858.639 167.985 879.187 168.088 904.587 168.088C929.987 168.088 950.534 167.882 950.534 167.779C950.534 167.572 929.987 167.469 904.587 167.469C879.29 167.469 858.639 167.572 858.639 167.779Z" fill="#FAFAFA"/>
        <path d="M860.186 159.725C860.186 159.931 880.424 160.035 905.411 160.035C930.398 160.035 950.636 159.828 950.636 159.725C950.636 159.518 930.398 159.415 905.411 159.415C880.424 159.415 860.186 159.622 860.186 159.725Z" fill="#FAFAFA"/>
        <path d="M876.75 177.36C876.446 163.563 876.338 152.375 876.51 152.371C876.681 152.367 877.065 163.549 877.369 177.346C877.673 191.144 877.78 202.332 877.609 202.335C877.438 202.339 877.053 191.157 876.75 177.36Z" fill="#263238"/>
        <path d="M886.517 184.196C886.517 184.196 874.127 185.125 871.959 188.326C869.687 191.63 866.383 200.923 867.622 201.026C868.861 201.129 869.894 200.303 869.894 200.303L873.301 194.314C873.301 194.314 872.785 200.097 874.747 200.2C876.812 200.303 886.827 202.368 886.827 202.368L886.517 184.196Z" fill="#FFBE9D"/>
        <path d="M951.05 170.566V202.471C950.637 209.906 943.616 215.275 936.388 213.623L886.517 203.297L886.62 182.853L929.677 191.114L930.296 169.431L951.05 170.566Z" fill="#857D8E"/>
        <path d="M922.552 211.041C922.552 211.041 922.346 210.938 921.933 210.835C921.416 210.732 920.797 210.628 919.971 210.422C918.216 210.009 915.737 209.493 912.64 208.873C906.238 207.531 897.358 205.775 886.723 203.607L886.93 203.917C886.93 197.515 886.93 190.391 886.93 182.957L886.517 183.266C889.305 183.783 892.299 184.402 895.293 184.918C907.89 187.293 919.661 189.565 929.573 191.423L929.986 191.527V191.114C929.677 181.614 929.367 173.664 929.16 168.088C929.057 165.404 928.954 163.235 928.851 161.687C928.851 160.964 928.747 160.448 928.747 160.035C928.747 159.622 928.747 159.415 928.644 159.415C928.644 159.415 928.644 159.622 928.644 160.035C928.644 160.448 928.644 161.067 928.644 161.687C928.644 163.235 928.747 165.404 928.747 168.088C928.851 173.664 929.057 181.614 929.264 191.114L929.573 190.804C919.661 188.842 907.89 186.57 895.293 184.196C892.299 183.576 889.305 183.06 886.517 182.544L886.104 182.44V182.853C886.104 190.391 886.104 197.412 886.104 203.814V204.123H886.31C896.945 206.188 905.928 207.944 912.33 209.183C915.428 209.802 917.906 210.215 919.661 210.525C920.487 210.628 921.107 210.732 921.623 210.835C922.346 211.041 922.552 211.145 922.552 211.041Z" fill="#263238"/>
        <path d="M893.951 127.82C893.951 127.82 893.848 127.923 893.744 128.336C893.641 128.646 893.538 129.162 893.331 129.782C893.125 131.021 892.918 132.879 893.538 135.151C894.157 137.319 895.603 139.797 897.875 141.862C900.146 143.927 903.45 145.373 906.961 145.269C910.471 145.269 913.879 143.824 915.944 141.656C918.112 139.384 919.041 136.699 919.248 134.428C919.454 132.156 919.041 130.298 918.628 129.059C918.422 128.439 918.215 128.026 918.009 127.716C917.802 127.407 917.699 127.303 917.699 127.303C917.596 127.303 918.009 127.923 918.422 129.162C918.835 130.298 919.145 132.156 918.835 134.325C918.525 136.493 917.596 139.074 915.531 141.139C913.569 143.204 910.368 144.547 907.064 144.547C903.657 144.547 900.559 143.308 898.391 141.346C896.222 139.384 894.777 137.112 894.157 135.047C893.538 132.982 893.538 131.124 893.744 129.885C893.744 128.542 893.951 127.923 893.951 127.82Z" fill="#263238"/>
        <path d="M891.473 205.053C891.577 205.053 891.577 203.814 891.473 201.955C891.37 199.993 891.267 197.309 891.267 194.418C891.267 191.423 891.267 188.842 891.37 186.88C891.37 184.918 891.473 183.783 891.37 183.783C891.267 183.783 891.06 184.918 890.854 186.88C890.647 188.842 890.544 191.527 890.544 194.521C890.544 197.515 890.751 200.2 890.957 202.162C891.164 203.917 891.473 205.053 891.473 205.053Z" fill="#FAFAFA"/>
        <path d="M894.055 205.569C894.158 205.569 894.158 204.33 894.158 202.471C894.158 200.303 894.158 197.722 894.055 194.934C894.055 192.146 894.055 189.565 894.055 187.397C894.055 185.435 894.055 184.299 893.952 184.299C893.848 184.299 893.745 185.435 893.539 187.397C893.435 189.358 893.332 191.94 893.332 194.934C893.332 197.928 893.435 200.51 893.642 202.471C893.848 204.433 893.952 205.569 894.055 205.569Z" fill="#FAFAFA"/>
        <path d="M844.184 332.26L834.788 358.176L821.365 376.555C820.436 377.898 820.746 379.756 822.191 380.582C823.017 381.098 823.947 381.202 824.773 380.789C830.142 378.517 849.966 370.257 850.586 368.295C851.309 366.024 864.009 338.145 864.009 338.145L844.184 332.26Z" fill="#C7C2CC"/>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M822.087 380.685L851.101 366.643L850.688 367.779C850.275 368.708 849.655 369.534 848.726 369.947C846.351 371.186 839.64 374.594 824.668 380.892C823.945 381.305 822.913 381.202 822.087 380.685Z" fill="white"/>
            </g>
        </g>
        <g opacity="0.6">
            <g opacity="0.6">
                <g opacity="0.6">
                    <path opacity="0.6" d="M849.139 356.318C848.313 356.524 847.694 357.454 847.9 358.28C848.107 359.106 848.933 359.725 849.759 359.519C850.585 359.312 851.308 358.28 851.101 357.454C850.895 356.628 849.759 356.008 849.036 356.318" fill="white"/>
                </g>
            </g>
        </g>
        <path d="M832.413 371.806C832.207 371.806 832.207 370.257 830.968 368.915C829.729 367.572 828.18 367.366 828.18 367.159C828.18 367.056 828.593 367.056 829.213 367.159C829.832 367.263 830.658 367.676 831.381 368.502C832.104 369.224 832.413 370.154 832.517 370.773C832.517 371.393 832.413 371.806 832.413 371.806Z" fill="#263238"/>
        <path d="M829.211 374.49C829.005 374.594 828.592 373.355 827.353 372.528C826.217 371.599 824.978 371.393 824.978 371.186C824.978 370.98 826.424 370.98 827.766 372.012C829.108 373.045 829.315 374.49 829.211 374.49Z" fill="#263238"/>
        <path d="M837.471 368.708C837.264 368.811 836.542 366.953 834.786 365.404C833.134 363.855 831.276 363.236 831.276 363.029C831.276 362.926 833.341 363.236 835.199 364.991C837.058 366.643 837.677 368.605 837.471 368.708Z" fill="#263238"/>
        <path d="M840.984 360.964C840.88 361.067 839.641 359.932 837.886 359.106C836.028 358.28 834.376 358.176 834.479 357.97C834.479 357.867 836.234 357.66 838.196 358.486C840.054 359.519 841.19 360.964 840.984 360.964Z" fill="#263238"/>
        <path d="M843.977 353.427C843.874 353.53 842.738 352.084 840.776 351.465C838.814 350.742 837.059 351.155 837.059 350.949C837.059 350.845 837.472 350.639 838.195 350.536C838.918 350.432 839.95 350.432 840.983 350.845C842.015 351.258 842.841 351.878 843.358 352.394C843.874 353.014 844.08 353.427 843.977 353.427Z" fill="#263238"/>
        <path d="M841.396 350.123C841.396 350.123 841.396 349.71 841.705 349.09C842.015 348.471 842.531 347.645 843.461 346.819C843.874 346.406 844.493 345.993 845.319 345.889C845.732 345.889 846.248 346.096 846.455 346.612C846.661 347.025 846.558 347.541 846.455 347.954C845.835 349.606 843.77 350.639 841.809 350.123C839.847 349.606 838.608 347.851 838.401 346.199C838.401 345.786 838.401 345.27 838.814 344.96C839.227 344.547 839.744 344.65 840.157 344.857C840.879 345.27 841.189 345.889 841.499 346.406C842.118 347.438 842.325 348.471 842.428 349.09C842.531 349.71 842.531 350.123 842.428 350.123C842.325 350.123 842.222 348.574 840.983 346.612C840.673 346.096 840.26 345.58 839.744 345.27C839.537 345.167 839.227 345.167 839.021 345.373C838.814 345.58 838.814 345.889 838.814 346.199C839.021 347.645 840.157 349.193 841.809 349.606C843.461 350.019 845.216 349.09 845.732 347.851C845.835 347.541 845.939 347.232 845.835 346.922C845.732 346.715 845.526 346.509 845.216 346.509C844.596 346.509 844.08 346.922 843.667 347.232C841.912 348.677 841.499 350.123 841.396 350.123Z" fill="#263238"/>
        <path d="M804.328 325.549L805.154 346.612C805.154 346.612 783.987 354.563 783.78 358.486L824.772 358.383L824.875 325.445L804.328 325.549Z" fill="#C7C2CC"/>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M817.853 344.754C818.679 344.96 819.195 345.889 818.989 346.612C818.782 347.438 817.956 347.954 817.13 347.748C816.304 347.541 815.685 346.509 815.891 345.683C816.201 344.857 817.233 344.341 817.956 344.65" fill="white"/>
            </g>
        </g>
        <g opacity="0.6">
            <path opacity="0.6" d="M824.668 358.28V354.976L785.329 356.318C785.329 356.318 783.47 357.144 783.677 358.383L824.668 358.28Z" fill="white"/>
        </g>
        <path d="M804.534 346.406C804.534 346.612 805.567 346.715 806.496 347.335C807.529 347.954 808.045 348.78 808.148 348.78C808.355 348.78 808.045 347.541 806.806 346.819C805.67 345.993 804.431 346.199 804.534 346.406Z" fill="#263238"/>
        <path d="M800.198 348.161C800.198 348.367 801.024 348.677 801.747 349.503C802.47 350.329 802.676 351.258 802.779 351.258C802.986 351.258 803.089 350.123 802.263 349.09C801.334 348.058 800.198 347.954 800.198 348.161Z" fill="#263238"/>
        <path d="M797.72 353.324C797.926 353.324 798.133 352.394 797.616 351.258C797.1 350.123 796.171 349.71 796.068 349.916C795.964 350.123 796.584 350.639 796.997 351.568C797.513 352.498 797.616 353.324 797.72 353.324Z" fill="#263238"/>
        <path d="M804.844 341.553C804.947 341.759 805.877 341.449 807.012 341.553C808.148 341.553 808.974 341.966 809.181 341.759C809.284 341.656 808.458 340.83 807.116 340.83C805.67 340.83 804.741 341.449 804.844 341.553Z" fill="#263238"/>
        <g opacity="0.3">
            <path opacity="0.3" d="M804.534 329.679L804.637 333.499L824.771 333.189V329.782L804.534 329.679Z" fill="black"/>
        </g>
        <path d="M804.74 337.319C804.74 337.423 805.566 337.526 806.805 337.319C807.424 337.216 808.147 337.01 808.973 336.7C809.386 336.493 809.799 336.287 810.109 336.08C810.316 335.977 810.522 335.874 810.729 335.564C810.935 335.358 811.038 334.841 810.832 334.532C810.625 334.222 810.212 334.119 809.902 334.119C809.593 334.119 809.386 334.222 809.18 334.222C808.767 334.325 808.25 334.532 807.941 334.635C807.115 334.945 806.495 335.461 806.082 335.874C805.153 336.803 804.843 337.526 804.843 337.526C804.946 337.629 805.359 337.01 806.392 336.184C806.908 335.771 807.528 335.461 808.25 335.151C808.663 335.048 808.973 334.841 809.386 334.738C809.799 334.635 810.212 334.635 810.316 334.841C810.316 334.945 810.316 334.945 810.316 335.048C810.212 335.151 810.006 335.254 809.902 335.358C809.489 335.564 809.18 335.771 808.87 335.977C808.147 336.287 807.424 336.597 806.908 336.7C805.463 337.113 804.74 337.216 804.74 337.319Z" fill="#263238"/>
        <path d="M805.05 337.629C805.153 337.629 805.566 336.906 805.566 335.667C805.566 335.048 805.566 334.222 805.257 333.396C805.153 332.983 804.947 332.57 804.74 332.157C804.534 331.744 804.224 331.227 803.398 331.124C802.985 331.124 802.675 331.434 802.572 331.744C802.469 332.053 802.366 332.26 802.366 332.466C802.262 332.983 802.262 333.396 802.366 333.809C802.469 334.635 802.882 335.358 803.295 335.977C804.121 337.01 804.947 337.319 804.947 337.216C805.05 337.113 804.327 336.7 803.708 335.667C803.398 335.151 803.088 334.532 803.088 333.706C803.088 333.293 803.088 332.879 803.088 332.57C803.192 332.157 803.295 331.744 803.501 331.847C803.708 331.847 804.018 332.157 804.224 332.466C804.431 332.879 804.637 333.189 804.74 333.602C805.05 334.325 805.153 335.048 805.153 335.667C805.153 336.803 804.947 337.526 805.05 337.629Z" fill="#263238"/>
        <path d="M824.772 224.361C848.933 226.839 881.045 230.143 881.045 230.143L876.708 269.586C876.708 269.586 829.522 257.092 829.935 258.125C830.245 259.157 827.767 341.966 827.767 341.966H799.372L798.856 248.212C798.546 234.067 810.73 222.915 824.772 224.361Z" fill="#263238"/>
        <path d="M938.97 240.262V252.446C938.97 260.809 929.47 269.586 921.21 269.586H914.809C909.749 269.586 901.799 265.869 897.669 262.874L895.191 261.119L865.66 352.704C865.66 352.704 831.793 346.612 831.793 345.58C831.793 344.547 854.716 270.618 865.247 236.235C869.584 222.193 884.556 214.036 898.495 218.785C899.011 218.992 899.424 219.095 899.94 219.302C915.015 225.393 938.97 240.262 938.97 240.262Z" fill="#263238"/>
        <path d="M831.793 345.58C831.793 345.58 831.897 345.476 831.897 345.27C832 344.96 832.103 344.65 832.31 344.341C832.619 343.515 833.136 342.172 833.652 340.52C834.788 337.216 836.337 332.363 838.195 326.375C842.015 314.397 846.972 297.877 852.547 279.601C855.335 270.412 857.916 261.738 860.291 253.788C861.427 249.864 862.563 246.044 863.595 242.533C864.111 240.778 864.628 239.023 865.144 237.474C865.66 235.822 866.073 234.273 866.693 232.828C867.829 229.937 869.687 227.768 871.442 226.116C873.301 224.464 874.953 223.225 876.399 222.193C877.844 221.263 878.98 220.541 879.806 220.024C880.116 219.818 880.425 219.611 880.735 219.508C880.942 219.405 881.045 219.302 881.045 219.302C881.045 219.302 880.942 219.302 880.735 219.405C880.529 219.508 880.219 219.715 879.806 219.921C878.98 220.334 877.844 221.057 876.295 221.986C874.85 222.915 873.094 224.154 871.133 225.807C869.274 227.459 867.416 229.73 866.177 232.621C865.557 234.067 865.041 235.616 864.524 237.268C864.008 238.92 863.492 240.572 862.976 242.327C861.943 245.838 860.807 249.658 859.568 253.581C857.194 261.532 854.509 270.205 851.824 279.395C846.352 297.67 841.293 314.191 837.679 326.168C835.82 332.157 834.375 336.906 833.342 340.314C832.826 341.966 832.413 343.205 832.103 344.134C832 344.547 831.897 344.857 831.793 345.167C831.793 345.476 831.793 345.58 831.793 345.58Z" fill="#455A64"/>
        <path d="M824.669 246.251C824.462 246.354 826.114 248.832 827.457 252.033C828.799 255.337 829.418 258.125 829.625 258.125C829.831 258.125 829.522 255.13 828.076 251.723C826.734 248.419 824.772 246.147 824.669 246.251Z" fill="#455A64"/>
        <path d="M819.196 250.071C819.093 250.277 821.571 251.31 824.049 253.375C826.631 255.337 828.179 257.505 828.386 257.402C828.592 257.299 827.147 254.924 824.462 252.859C821.984 250.69 819.3 249.968 819.196 250.071Z" fill="#455A64"/>
        <path d="M865.349 352.704C865.555 352.807 873.506 328.233 883.211 297.98C892.917 267.727 900.558 243.05 900.351 243.05C900.145 242.946 892.194 267.521 882.489 297.774C872.783 328.027 865.142 352.601 865.349 352.704Z" fill="#455A64"/>
        <path d="M901.591 209.802L804.843 210.732L786.774 159.725L857.192 160.241L873.506 206.085L901.591 206.498V209.802Z" fill="#455A64"/>
        <path d="M823.819 186.31C823.516 184.165 825.01 182.179 827.156 181.876C829.301 181.573 831.287 183.067 831.59 185.213C831.893 187.358 830.399 189.343 828.254 189.647C826.108 189.95 824.123 188.456 823.819 186.31Z" fill="white"/>
        <path d="M909.441 71.7535C908.511 68.3462 905.93 66.6942 902.626 65.5584C899.322 64.4226 895.398 65.0421 892.61 67.2104C890.752 68.656 889.306 70.721 887.035 71.4438C883.421 72.6828 879.084 69.9983 875.574 71.6503C873.509 72.5796 872.27 75.1609 872.889 77.3292C873.509 79.4975 875.677 81.2528 877.949 81.1495C875.574 81.9756 874.851 85.3829 876.193 87.5512C877.536 89.7195 880.427 90.4423 882.905 90.0293C885.383 89.513 887.551 88.0675 889.513 86.3122C891.371 84.6601 893.127 82.6983 895.088 81.1495C897.154 79.6007 899.528 78.465 902.11 78.5682C905.517 78.7747 908.511 81.356 910.06 84.4536C911.609 87.5512 911.919 91.0618 912.125 94.4691C912.332 97.8765 912.332 101.387 913.364 104.691C913.467 105.104 913.674 105.517 914.087 105.62C914.603 105.827 915.12 105.207 915.429 104.691C918.527 98.909 920.489 92.5073 921.211 85.8992C921.624 82.0788 921.521 78.465 919.456 75.2641C917.391 72.0633 912.125 69.7917 909.441 71.7535Z" fill="#263238"/>
        <path d="M916.151 87.7577L917.906 126.271C918.216 132.776 917.287 142.482 906.962 142.482C897.979 141.759 895.294 133.395 894.984 127.303C894.881 124.412 894.778 122.038 894.675 121.728C894.675 121.728 884.866 121.212 883.626 111.609C883.11 106.963 882.697 97.257 882.491 88.3772C882.284 78.878 889.305 70.9275 898.701 69.895L899.631 69.7917C909.853 69.2755 915.945 77.5357 916.151 87.7577Z" fill="#FFBE9D"/>
        <path d="M894.673 121.521C894.673 121.521 900.868 121.418 906.96 116.565C906.96 116.565 904.688 123.896 894.983 123.999L894.673 121.521Z" fill="#EB996E"/>
        <path d="M886.104 94.4691C886.207 95.1919 886.827 95.8114 887.549 95.7082C888.272 95.7082 888.892 95.0886 888.892 94.2626C888.788 93.5399 888.169 92.9203 887.446 93.0236C886.62 93.1268 886.001 93.7464 886.104 94.4691Z" fill="#263238"/>
        <path d="M885.174 92.8171C885.38 93.0236 886.309 92.0943 887.858 91.8878C889.304 91.6813 890.543 92.4041 890.646 92.1976C890.749 92.0943 890.543 91.7846 890.027 91.4748C889.51 91.165 888.684 90.9585 887.755 91.0618C886.826 91.165 886.103 91.5781 885.69 91.9911C885.174 92.4041 885.07 92.8171 885.174 92.8171Z" fill="#263238"/>
        <path d="M900.661 93.4366C900.765 94.1594 901.384 94.7789 902.107 94.6756C902.83 94.6756 903.449 94.0561 903.449 93.2301C903.346 92.5073 902.726 91.8878 902.004 91.9911C901.281 92.0943 900.661 92.7138 900.661 93.4366Z" fill="#263238"/>
        <path d="M899.836 91.5781C900.042 91.7846 900.972 90.8553 902.52 90.6488C903.966 90.4423 905.205 91.165 905.308 90.9585C905.411 90.8553 905.205 90.5455 904.689 90.2358C904.172 89.926 903.346 89.7195 902.417 89.8228C901.488 89.926 900.765 90.339 900.352 90.752C899.836 91.165 899.733 91.4748 899.836 91.5781Z" fill="#263238"/>
        <path d="M895.604 103.142C895.604 103.039 894.674 103.039 893.125 102.936C892.712 102.936 892.403 102.936 892.299 102.626C892.196 102.316 892.299 101.903 892.403 101.49C892.712 100.561 892.919 99.5285 893.229 98.496C894.364 94.1594 895.087 90.752 894.984 90.6488C894.778 90.6488 893.745 94.0561 892.609 98.2895C892.299 99.322 892.093 100.355 891.886 101.284C891.783 101.697 891.577 102.213 891.886 102.833C891.99 103.142 892.299 103.246 892.609 103.349C892.816 103.349 893.125 103.349 893.229 103.349C894.674 103.349 895.604 103.246 895.604 103.142Z" fill="#263238"/>
        <path d="M900.043 103.762C899.836 103.762 899.94 105.414 898.7 106.653C897.461 107.995 895.706 107.995 895.706 108.202C895.706 108.305 896.119 108.511 896.842 108.408C897.565 108.408 898.597 107.995 899.32 107.272C900.043 106.446 900.353 105.517 900.353 104.794C900.353 104.072 900.146 103.762 900.043 103.762Z" fill="#263238"/>
        <path d="M898.803 85.7959C899.01 86.2089 900.456 85.7959 902.211 85.8992C903.966 85.8992 905.412 86.2089 905.618 85.8992C905.721 85.6927 905.412 85.3829 904.792 85.0731C904.173 84.7634 903.243 84.4536 902.211 84.4536C901.178 84.4536 900.249 84.7634 899.629 85.0731C899.01 85.2796 898.7 85.5894 898.803 85.7959Z" fill="#263238"/>
        <path d="M885.173 88.4805C885.483 88.7902 886.412 88.3772 887.651 88.1707C888.89 87.9642 889.923 88.1707 890.129 87.7577C890.233 87.5512 890.026 87.2414 889.51 86.9317C888.994 86.6219 888.271 86.5187 887.445 86.6219C886.619 86.7252 885.896 87.1382 885.483 87.5512C885.173 87.9642 885.07 88.3772 885.173 88.4805Z" fill="#263238"/>
        <path d="M916.048 94.9854C916.255 94.8821 922.45 92.3008 922.966 98.909C923.482 105.517 916.668 104.691 916.668 104.485C916.668 104.381 916.048 94.9854 916.048 94.9854Z" fill="#FFBE9D"/>
        <path d="M918.526 101.903C918.526 101.903 918.629 102.007 918.836 102.007C919.042 102.11 919.352 102.11 919.662 102.007C920.384 101.697 920.901 100.561 920.797 99.322C920.797 98.7025 920.591 98.1862 920.384 97.67C920.178 97.1537 919.765 96.8439 919.455 96.8439C919.145 96.7407 918.836 97.0504 918.836 97.257C918.732 97.4635 918.836 97.5667 918.732 97.5667C918.732 97.5667 918.629 97.4635 918.629 97.1537C918.629 97.0504 918.732 96.8439 918.836 96.7407C918.939 96.6374 919.249 96.5342 919.455 96.5342C919.971 96.5342 920.488 96.9472 920.694 97.4635C921.004 97.9797 921.21 98.5992 921.21 99.322C921.314 100.664 920.694 102.007 919.765 102.316C919.352 102.523 918.939 102.316 918.732 102.213C918.629 102.007 918.526 101.903 918.526 101.903Z" fill="#EB996E"/>
        <path d="M880.322 86.5187C884.658 87.2414 889.408 85.5894 892.402 82.3886C894.571 80.117 896.016 77.1227 898.907 75.8836C900.869 75.0576 903.037 75.2641 904.999 76.0902C906.961 76.9162 908.613 78.2585 909.955 79.8072C908.613 84.9699 910.472 90.339 912.227 95.2951C912.433 95.8114 912.64 96.4309 913.156 96.7407C913.982 97.2569 915.118 96.6374 915.737 95.9147C916.667 94.6756 916.976 93.0236 917.08 91.3715C917.389 87.7577 917.08 84.0406 915.841 80.6333C914.705 77.2259 912.64 74.0251 909.749 71.8568C906.135 69.069 901.385 67.9332 896.945 68.5527C892.402 69.069 888.169 71.2373 884.865 74.3349C883.316 75.7804 881.974 77.4324 881.251 79.4975" fill="#263238"/>
        <path d="M1032 210.112V219.302H981.613V354.872H976.966V219.302L766.537 220.334V361.786H761.994V220.024L729.47 220.334V209.389L1032 210.112Z" fill="#263238"/>
        <path d="M999.784 217.443L979.856 231.795L981.612 233.447L1001.64 218.372L999.784 217.443Z" fill="#263238"/>
        <path d="M743.202 218.785L766.331 235.202L764.576 236.855L741.344 219.715L743.202 218.785Z" fill="#263238"/>
        <path d="M906.651 206.808H958.69V210.628H906.651V206.808Z" fill="#263238"/>
        <g clip-path="url(#clip0)">
            <path d="M1011.07 15.1108L1010.07 17.2759L1121.58 68.4343L1122.57 66.2692L1011.07 15.1108Z" fill="#263238"/>
            <path d="M1096.47 55.0145H1094.09V362H1096.47V55.0145Z" fill="#263238"/>
            <path d="M1095.29 177.428H1095.76C1097.31 177.428 1098.5 176.237 1098.5 174.689V139.084C1098.5 137.536 1097.31 136.345 1095.76 136.345H1095.29C1093.74 136.345 1092.55 137.536 1092.55 139.084V174.689C1092.55 176.118 1093.74 177.428 1095.29 177.428Z" fill="#455A64"/>
            <path d="M1013.2 15.9566L1010.11 21.3151L996.188 13.2178L999.756 7.74013C1002.02 4.28684 1006.54 3.09605 1010.11 5.00131C1014.03 7.14473 1015.46 12.146 1013.2 15.9566Z" fill="#455A64"/>
            <path d="M1010.58 19.2908L1018.43 56.6816L961.093 24.173L996.187 13.3368L1010.58 19.2908Z" fill="#455A64"/>
            <path d="M1018.69 58.3885C1021.57 53.0672 1010.41 41.4447 993.764 32.4291C977.122 23.4134 961.299 20.4186 958.422 25.7399C955.544 31.0613 966.703 42.6837 983.345 51.6994C999.987 60.715 1015.81 63.7099 1018.69 58.3885Z" fill="#263238"/>
            <path d="M1128 362H1061.74C1061.74 362 1071.61 351.997 1095.28 352.95C1118.96 353.784 1128 362 1128 362Z" fill="#263238"/>
            <path d="M1095.28 59.5395C1097.78 59.5395 1099.8 57.5136 1099.8 55.0145C1099.8 52.5154 1097.78 50.4895 1095.28 50.4895C1092.79 50.4895 1090.76 52.5154 1090.76 55.0145C1090.76 57.5136 1092.79 59.5395 1095.28 59.5395Z" fill="#455A64"/>
            <path d="M1010.58 6.31118L1013.79 1.07171L1011.06 0L1008.56 5.59671L1010.58 6.31118Z" fill="#455A64"/>
            <path d="M996.188 12.9796C996.188 13.2178 1000.11 12.9796 1004.4 15.0039C1008.68 16.9092 1011.06 20.1243 1011.18 20.0053C1011.18 20.0053 1010.7 19.1717 1009.63 17.9809C1008.56 16.9092 1006.78 15.4803 1004.63 14.4086C1002.49 13.4559 1000.23 12.9796 998.686 12.8605C997.258 12.7414 996.188 12.8605 996.188 12.9796Z" fill="#263238"/>
            <path d="M997.972 34.8901V46.0836C997.972 47.3934 997.615 48.7033 996.663 49.6559C995.236 51.323 992.262 52.5138 986.313 49.4178C979.295 45.7263 980.008 41.5586 981.079 39.2961C981.555 38.3434 982.388 37.5099 983.22 37.0336L992.143 31.7941L997.972 34.8901Z" fill="#FAFAFA"/>
            <path d="M994.165 32.7467C994.165 32.7467 993.927 33.223 993.451 34.0566C993.094 34.8901 992.381 36.0809 991.548 37.5099C991.072 38.2243 990.596 38.9388 990.001 39.7724C989.763 40.1296 989.407 40.6059 989.05 40.8441C988.693 41.0822 988.217 41.3204 987.741 41.0822C987.384 40.9632 987.027 40.4868 987.027 40.0105C987.027 39.5342 987.384 39.177 987.86 39.0579C988.336 38.9388 988.931 39.177 989.407 39.4151C990.001 39.6533 990.477 40.0105 991.072 40.2487C991.667 40.6059 992.143 40.9632 992.619 41.3204C993.094 41.6776 993.57 42.0349 993.451 42.5112C993.451 42.9875 993.094 43.5829 992.857 43.9401C992.5 44.2974 992.143 44.1783 991.786 43.8211C991.548 43.4638 991.548 42.9875 991.667 42.5112C991.786 42.0349 992.143 41.6776 992.381 41.2013C992.857 40.3678 993.332 39.5342 993.689 38.8197C994.403 37.3908 995.117 36.2 995.593 35.3665C996.069 34.5329 996.307 34.0566 996.188 34.0566C996.188 34.0566 995.831 34.4138 995.355 35.2474C994.76 36.2 994.165 37.2717 993.332 38.7007C992.857 39.4151 992.381 40.2487 991.905 41.0822C991.667 41.5586 991.429 41.9158 991.191 42.5112C991.072 42.7493 991.072 43.1066 991.072 43.3447C991.072 43.702 991.191 44.0592 991.429 44.2974C991.667 44.5355 991.905 44.7737 992.262 44.8928C992.619 45.0118 993.094 44.7737 993.332 44.5355C993.57 44.2974 993.689 43.9401 993.808 43.702C993.927 43.4638 994.046 43.1066 994.046 42.7493C994.046 42.3921 993.927 41.9158 993.689 41.6776C993.451 41.4395 993.213 41.2013 992.976 40.9632C992.5 40.6059 991.905 40.2487 991.31 39.8915C990.715 39.5342 990.239 39.2961 989.645 38.9388C989.05 38.7007 988.455 38.4625 987.741 38.5816C987.146 38.7007 986.551 39.2961 986.551 40.0105C986.432 40.725 987.027 41.4395 987.622 41.6776C988.336 41.9158 988.931 41.6776 989.407 41.3204C989.882 40.9632 990.239 40.4868 990.477 40.1296C991.072 39.2961 991.548 38.5816 992.024 37.748C992.857 36.3191 993.451 35.0092 993.808 34.1757C994.046 33.3421 994.165 32.7467 994.165 32.7467Z" fill="#263238"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="170" height="362" fill="white" transform="translate(958)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const InformacoesIllustration = defineComponent({
});

export default InformacoesIllustration;
</script>

<style>

</style>