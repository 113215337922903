
import { defineComponent } from 'vue';
import { useAlert } from '@/plugins/alert';
import vm from '@/viewModels/MainViewModel';
import { useRouter } from 'vue-router';

//  Components
import Header from '@/components/Header.vue';

const CandidatoMainPage = defineComponent({
    components: { Header },
    setup() {

        const alert = useAlert();
        const router = useRouter();

        /** Logout */
        const logout = () => {
            alert({
                icon: 'logout',
                title: 'Logout',
                message: 'Tem certeza que deseja sair do sistema?',
                actions: [
                    {
                        title: 'Cancelar',
                        primary: true
                    },
                    {
                        title: 'Sair',
                        action: () => {
                            vm.logout();
                            router.replace({ name: 'login-candidato' });
                        }
                    }
                ]
            });
        };

        return { logout, vm };
    }
});

export default CandidatoMainPage;
