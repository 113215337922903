
import { computed, defineComponent, onBeforeUnmount, onMounted } from 'vue';

const Timer = defineComponent({
    props: {
        autoStart: {
            type: Boolean,
            default: true
        },
        modelValue: {
            type: Number,
            required: true
        }
    },
    setup(props, context) {
        let interval: any  = 0;

        const hours = computed(() => Math.trunc(props.modelValue / 3600000));

        const timeString = computed(() => {
            const minutes = Math.trunc((props.modelValue % 3600000) / 60000);
            const seconds = Math.trunc(((props.modelValue % 3600000) % 60000) / 1000);

            return hours.value > 0 ?
                hours.value.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0') :
                minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
        });

        const stop = () => clearInterval(interval);

        const start = () => {
            stop();
            if(props.modelValue > 0) {
                interval = setInterval(() => {
                    const valueTemp = props.modelValue - 1000;
                    context.emit("update:modelValue", valueTemp);
                    if(valueTemp <= 0) {
                        context.emit('finished');
                        stop();
                    }
                }, 1000);
            }
        };

        // Ciclo de vida
        onMounted(() => {
            if(props.autoStart) {
                start();
            }
        });
        onBeforeUnmount(() => stop());

        return { timeString };
    }
});

export default Timer;
