<template>
    <svg viewBox="0 0 124 107" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <path d="M56.325 91.7662C57.2872 91.2566 57.625 90.6507 56.2423 89.9862C53.1831 88.3675 51.4464 85.9752 51.3493 85.0203C51.3228 84.0949 52.6678 83.6632 53.3074 84.5138C53.8355 85.2345 55.0585 86.0811 56.0585 86.6678C56.8466 87.1653 57.694 87.5217 58.6715 87.7664C59.5077 87.9518 60.173 88.1484 60.7974 88.2447C60.7974 88.2447 64.4842 88.9637 66.3717 88.4276L70.2977 99.0538L65.3751 101.472C65.3751 101.472 62.7412 103.19 60.0351 101.72C57.2584 100.22 54.3059 96.5685 54.3059 96.5685C54.1381 95.5838 48.2894 96.449 46.3609 96.8848C44.4325 97.3205 43.4213 96.563 43.9076 95.604C44.2752 94.9274 50.1496 93.4082 52.0483 93.043C53.0924 92.7341 55.1621 92.3577 56.325 91.7662Z" fill="#FFBE9D"/>
        <path d="M57.6999 100.156C57.6702 100.227 57.8524 100.387 58.2055 100.535C58.5586 100.684 59.1123 100.75 59.7552 100.605C60.3273 100.43 60.788 100.125 61.0777 99.8314C61.3674 99.5377 61.3859 99.2962 61.3859 99.2962C61.3152 99.2665 60.7471 100.025 59.7327 100.263C58.618 100.542 57.7296 100.086 57.6999 100.156Z" fill="#EB996E"/>
        <path d="M55.349 97.839C55.3193 97.9096 55.5015 98.0693 55.8843 98.1472C56.2374 98.2956 56.7912 98.3622 57.3634 98.1873C57.9356 98.0123 58.3962 97.7074 58.6153 97.384C58.905 97.0902 58.9235 96.8487 58.9235 96.8487C58.8528 96.819 58.3143 97.5067 57.3 97.7451C56.2262 98.1247 55.3787 97.7684 55.349 97.839Z" fill="#EB996E"/>
        <path d="M52.0867 13.492C51.7802 20.3103 43.5063 29.5802 37.3009 31.1124C34.0833 31.8785 30.3294 31.4954 27.8012 33.7171C23.4344 37.471 25.9626 45.2086 22.9748 50.1883C21.1361 53.2527 17.5355 54.7849 15.467 57.6961C12.0195 62.5225 14.3178 69.8771 18.991 73.4778C23.6643 77.0785 29.8697 77.768 35.6921 77.6148C48.486 77.3083 61.6629 73.1714 70.9328 64.1314C75.2995 59.8412 92.307 46.6642 86.408 15.4073" fill="#2D3031"/>
        <path d="M5.24909 61.2378L51.689 46.6707C55.5529 44.9751 58.469 44.5127 58.469 44.5127L74.1434 44.5897C76.2576 44.5897 78.2989 45.0522 80.1215 45.9L108.998 58.2088C116.417 61.0621 123.265 65.0568 123.265 69.6389C123.265 76.487 120.412 78.2712 116.417 79.8943L100.899 77.7317C100.972 77.9629 100.972 78.04 100.972 78.04L33.0984 78.2712L7.21751 78.3483C3.20779 78.3483 0 74.4175 0 69.6389C0 65.7081 2.11422 62.3168 5.24909 61.2378Z" fill="#04986D"/>
        <path d="M15.5445 66.4997C15.5445 66.4997 15.6978 66.4997 16.0042 66.4997C16.3107 66.4997 16.7703 66.4231 17.23 66.3464C18.3025 66.1932 19.9113 66.04 21.8266 65.8102C25.7337 65.4271 31.173 65.1207 37.1486 65.1973C43.1242 65.2739 48.5636 65.7336 52.3941 66.2698C54.3093 66.4997 55.9181 66.7295 56.9907 66.8827C57.527 66.9593 57.91 67.0359 58.2164 67.0359C58.5229 67.0359 58.6761 67.1125 58.6761 67.0359C58.6761 67.0359 58.5229 66.9593 58.2164 66.9593C57.91 66.8827 57.4503 66.8061 56.9907 66.7295C55.9181 66.4997 54.3093 66.2698 52.3941 65.9634C48.487 65.4271 43.1242 64.8908 37.072 64.8142C31.0964 64.7376 25.6571 65.1207 21.75 65.5803C19.8347 65.8102 18.2259 66.04 17.1534 66.1932C16.6171 66.2698 16.234 66.3464 15.9276 66.4231C15.6978 66.4231 15.5445 66.4997 15.5445 66.4997Z" fill="#263238"/>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M26.9607 55.3354C26.9607 55.3354 27.0298 55.4045 27.0989 55.4736C27.168 55.6118 27.3062 55.681 27.5136 55.8883C27.8592 56.2339 28.3429 56.7868 29.1032 57.4088C29.7943 58.0308 30.6928 58.7911 31.7986 59.5513C32.9044 60.3116 34.2867 61.0718 35.8763 61.4865C37.535 61.9703 39.4702 61.9012 41.4053 61.3482C43.3405 60.8645 45.2757 59.8278 47.2109 58.7911C49.146 57.7544 51.2194 56.5794 53.5002 55.8192C55.7809 55.0589 58.3381 55.0589 60.6879 56.1648C63.0378 57.2015 64.973 58.7911 67.1155 59.966C67.6684 60.2424 68.2213 60.5189 68.7742 60.7262C69.3271 60.9336 69.88 61.1409 70.4329 61.2791C71.5387 61.6247 72.7136 61.7629 73.8195 61.832C78.3118 62.1776 82.3204 60.7262 85.0849 58.7219C86.4672 57.7544 87.5039 56.6485 88.4715 55.681C89.439 54.7825 90.3375 54.0222 91.0978 53.5385C92.6874 52.5709 93.7932 52.5709 93.7932 52.5018C93.7932 52.5018 93.5167 52.5018 93.0329 52.5709C92.5491 52.64 91.858 52.9164 91.0287 53.4002C90.1993 53.884 89.3008 54.6443 88.3332 55.5427C87.3656 56.4412 86.2598 57.547 84.9467 58.5146C82.2513 60.3807 78.2427 61.832 73.8886 61.4865C72.7828 61.4174 71.6769 61.21 70.5711 60.9336C70.0182 60.7953 69.4653 60.588 68.9124 60.3807C68.3595 60.1042 67.8066 59.8969 67.3228 59.6204C65.1803 58.5146 63.2451 56.925 60.8953 55.8192C58.5454 54.7134 55.7809 54.6443 53.5002 55.4736C51.1503 56.1648 49.146 57.4088 47.2109 58.4455C45.2757 59.4822 43.4096 60.5189 41.4745 61.0027C39.6084 61.5556 37.7423 61.5556 36.1527 61.1409C34.5631 60.7262 33.1809 59.966 32.0751 59.2749C30.9692 58.5146 30.0708 57.8235 29.3796 57.2015C28.6885 56.5794 28.1356 56.0956 27.79 55.7501C27.6518 55.6118 27.5136 55.4736 27.3754 55.3354C27.0298 55.4045 26.9607 55.3354 26.9607 55.3354Z" fill="white"/>
            </g>
        </g>
        <g opacity="0.2">
            <path opacity="0.2" d="M102.725 66.3464L71.9278 72.1688L75.5285 77.9912H76.4478L102.725 66.3464Z" fill="black"/>
        </g>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M9.41756 60.2942C9.41756 60.2942 9.41756 60.4474 9.41756 60.7538C9.41756 61.0603 9.49418 61.5199 9.57079 62.1328C9.80062 63.282 10.2603 64.9674 11.4094 66.7294C12.5586 68.4915 14.5505 70.2535 17.2318 71.1728C18.5342 71.6325 20.0664 71.9389 21.5986 71.8623C23.1308 71.7857 24.663 71.4793 26.1952 71.0196C27.7274 70.5599 29.2596 70.1003 30.7152 69.7938C32.1708 69.4874 33.6264 69.4108 35.0054 69.564C37.7634 69.8705 39.985 71.0196 41.6705 72.322C43.3559 73.5477 44.3518 74.9267 44.9647 75.9227C45.1179 76.1525 45.2712 76.3823 45.3478 76.6121C45.4244 76.7654 45.501 76.9952 45.5776 77.1484C45.7308 77.4549 45.8074 77.5315 45.8074 77.5315C45.8074 77.5315 45.7308 77.3783 45.6542 77.0718C45.5776 76.9186 45.501 76.7654 45.4244 76.5355C45.3478 76.3057 45.1945 76.0759 45.0413 75.846C44.4284 74.8501 43.4325 73.3945 41.7471 72.0921C40.1383 70.7898 37.7634 69.564 35.0054 69.2576C33.6264 69.1043 32.0942 69.1044 30.6386 69.4874C29.1064 69.7938 27.6508 70.2535 26.1186 70.7132C24.5864 71.1728 23.0542 71.4793 21.5986 71.5559C20.143 71.6325 18.6874 71.4027 17.385 70.943C14.7803 70.1003 12.865 68.3382 11.6393 66.6528C10.4135 64.9674 9.95384 63.282 9.72401 62.1328C9.49418 60.9071 9.49417 60.2942 9.41756 60.2942Z" fill="white"/>
            </g>
        </g>
        <path d="M58.2092 78.2239V67.0388C58.2092 67.0388 74.9102 70.4863 75.2166 78.1473L58.2092 78.2239Z" fill="#FFBE9D"/>
        <path d="M119.259 78.6026C119.259 78.6026 68.4568 101.347 68.7605 100.809C69.0642 100.271 59.7895 84.1528 59.7895 84.1528L104.876 61.5548L119.259 78.6026Z" fill="#04986D"/>
        <path d="M98.5388 64.8497C98.5388 64.8497 98.1557 65.0029 97.3896 65.3093C96.6235 65.6924 95.551 66.152 94.3252 66.7649C91.7205 67.9907 88.1964 69.7527 84.2893 71.668C80.3822 73.5832 76.8581 75.3453 74.2533 76.6476C73.0276 77.2605 72.0316 77.7968 71.2655 78.1798C70.576 78.5629 70.193 78.7927 70.193 78.7927C70.193 78.7927 70.576 78.6395 71.3421 78.3331C72.1082 77.95 73.1808 77.4904 74.4066 76.8775C77.0113 75.6517 80.5354 73.8897 84.4425 71.9744C88.3496 70.0592 91.8737 68.2971 94.4784 66.9948C95.7042 66.3819 96.7001 65.8456 97.4662 65.4625C98.1557 65.0795 98.5388 64.8497 98.5388 64.8497Z" fill="#263238"/>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M86.2813 72.2811C86.2813 72.2811 86.2813 72.894 86.7662 73.9665C87.1817 74.9625 88.1514 76.3414 89.7445 77.2608C90.5757 77.7204 91.4761 78.1801 92.5151 78.3333C93.554 78.5631 94.7315 78.5631 95.909 78.4099C98.3333 78.1035 100.758 76.9543 103.251 75.652C105.745 74.3496 108.1 73.1238 110.385 72.5109C111.563 72.2045 112.671 72.0513 113.71 72.0513C114.749 72.0513 115.719 72.2811 116.55 72.5875C118.212 73.2004 119.459 74.1964 120.082 75.0391C120.706 75.8818 120.983 76.4181 120.983 76.4181C120.983 76.4181 120.844 75.8052 120.151 74.9625C119.528 74.0431 118.351 72.9706 116.619 72.3577C115.788 72.0513 114.749 71.8214 113.71 71.7448C112.671 71.6682 111.493 71.8214 110.316 72.1279C107.961 72.7408 105.537 73.9665 103.112 75.2689C100.619 76.5713 98.1947 77.6438 95.8398 78.0269C94.6623 78.1801 93.554 78.1801 92.5151 78.0269C91.4761 77.8736 90.5757 77.4906 89.8138 77.0309C88.2207 76.1116 87.251 74.8858 86.8354 73.8899C86.4198 72.894 86.3506 72.2811 86.2813 72.2811Z" fill="white"/>
            </g>
        </g>
        <g opacity="0.6">
            <g opacity="0.6">
                <path opacity="0.6" d="M62.6101 89.1359C62.6101 89.2125 63.8359 88.9826 65.8277 88.9826C66.8237 88.9826 67.9728 88.9826 69.2752 89.1359C70.5776 89.2891 72.0332 89.5955 73.4888 89.9786C74.9444 90.3616 76.3233 90.8979 77.4725 91.4342C78.6217 91.9704 79.6942 92.5833 80.5369 93.1196C82.2223 94.1921 83.1417 95.0348 83.2183 94.9582C83.2183 94.9582 82.9884 94.7284 82.6054 94.3453C82.2223 93.9623 81.5328 93.426 80.6901 92.8898C79.8474 92.2769 78.8515 91.7406 77.6257 91.1277C76.4 90.5914 75.021 89.9786 73.5654 89.5955C72.1098 89.2125 70.6542 88.906 69.2752 88.7528C67.9728 88.5996 66.7471 88.5996 65.7511 88.6762C64.7552 88.7528 63.9125 88.8294 63.3762 88.906C62.9166 89.0592 62.6101 89.1359 62.6101 89.1359Z" fill="white"/>
            </g>
        </g>
        <g opacity="0.2">
            <path opacity="0.2" d="M60.4364 44.5124C61.3557 47.1171 59.9767 49.8751 58.5977 52.25C57.6784 53.9354 56.6825 55.6209 55.3801 56.9232C54.0011 58.3022 52.2391 59.2982 50.3238 59.2216C47.7957 59.1449 45.574 57.3063 43.0459 57.4595C41.3604 57.5361 39.8282 58.5321 38.4493 59.528C35.9977 61.2134 33.4696 62.8222 30.7116 63.8182C27.9537 64.8141 24.8892 65.1971 22.0547 64.3544C19.2201 63.5117 16.692 61.29 16.0791 58.3788" fill="black"/>
        </g>
        <path d="M60.2087 44.5962C60.0555 41.6084 59.9023 38.8504 59.9023 38.8504C59.9023 38.8504 53.1606 38.0077 52.9307 31.1894C52.6243 24.3711 53.3138 8.58939 53.3138 8.58939L65.648 2.6904L78.9016 13.4158L76.8331 44.826C76.5267 49.346 72.6962 52.7935 68.0996 52.6403C63.8094 52.4104 60.3619 48.963 60.2087 44.5962Z" fill="#FFBE9D"/>
        <path d="M55.295 22.7026C54.8297 22.6945 54.459 22.3107 54.4671 21.8454C54.4752 21.3801 54.8589 21.0094 55.3243 21.0175C55.7896 21.0256 56.1603 21.4093 56.1522 21.8746C56.1441 22.34 55.7604 22.7106 55.295 22.7026Z" fill="#263238"/>
        <path d="M53.6201 19.1612C53.6967 19.3144 54.3862 18.7015 55.3056 18.7015C56.2249 18.6249 56.9144 19.1612 56.991 19.0079C57.0676 18.9313 56.9144 18.7781 56.6079 18.5483C56.3015 18.3184 55.8418 18.1652 55.2289 18.1652C54.6927 18.1652 54.1564 18.4717 53.9266 18.7015C53.6967 18.8547 53.5435 19.0845 53.6201 19.1612Z" fill="#263238"/>
        <path d="M65.4838 22.7029C65.0185 22.6948 64.6478 22.311 64.6559 21.8457C64.6639 21.3804 65.0477 21.0097 65.513 21.0178C65.9783 21.0259 66.349 21.4096 66.341 21.8749C66.3329 22.3403 65.9491 22.7109 65.4838 22.7029Z" fill="#263238"/>
        <path d="M63.8107 19.3148C63.8873 19.468 64.5768 18.8551 65.4961 18.8551C66.4154 18.7785 67.1049 19.3148 67.1815 19.1615C67.2581 19.0849 67.1049 18.9317 66.7985 18.7019C66.492 18.4721 66.0324 18.3188 65.4195 18.3188C64.8832 18.3188 64.347 18.6253 64.1171 18.8551C63.8873 19.0849 63.7341 19.3148 63.8107 19.3148Z" fill="#263238"/>
        <path d="M60.3638 27.2056C60.3638 27.129 59.8276 27.0524 58.8316 26.9758C58.6018 26.9758 58.372 26.8992 58.2954 26.746C58.2187 26.5928 58.2954 26.2863 58.4486 25.9799C58.6784 25.367 58.9082 24.7541 59.1381 24.0646C60.0574 21.3067 60.7469 19.0083 60.5937 19.0083C60.5171 19.0083 59.6743 21.1534 58.755 23.9114C58.5252 24.6009 58.2954 25.2138 58.1421 25.8267C58.0655 26.1331 57.9123 26.4395 58.0655 26.8226C58.1421 26.9758 58.2954 27.129 58.4486 27.2056C58.6018 27.2822 58.755 27.2823 58.8316 27.2823C59.751 27.2823 60.3638 27.2822 60.3638 27.2056Z" fill="#263238"/>
        <path d="M63.3499 28.202C64.2692 27.5891 60.7452 29.7342 58.9065 29.6575C58.9065 29.6575 60.0557 31.4196 61.9709 30.5769C63.8862 29.8108 63.3499 28.202 63.3499 28.202Z" fill="white"/>
        <path d="M59.9032 38.9272C59.9032 38.9272 64.4232 39.0804 68.79 36.016C68.79 36.016 66.7215 40.9957 60.0565 40.6126L59.9032 38.9272Z" fill="#EB996E"/>
        <path d="M86.1015 20.1576C86.1015 26.0566 87.7103 41.7617 76.9083 43.9068L77.2913 22.3027C77.2913 22.3027 66.8724 17.3997 65.0337 7.13389L64.9571 7.2105C64.2676 8.7427 63.1185 12.1136 63.8079 14.7949L54.0018 17.2464L52.8527 18.2424C52.8527 18.2424 49.3286 10.5813 56.0703 3.68643C62.812 -3.20849 68.5578 1.77117 72.1585 1.31151C74.5334 1.00507 82.8839 1.08168 86.2547 15.4078L86.1015 20.1576Z" fill="#2D3031"/>
        <path d="M77.0645 22.5325C77.9838 21.9962 79.2096 22.4559 79.9757 23.222C81.1248 24.4478 81.2781 26.4396 80.4354 27.8952C79.5926 29.3508 77.6774 30.1169 76.0686 29.6572" fill="#FFBE9D"/>
        <path d="M77.2171 27.6656C77.2171 27.7422 77.5236 27.7422 77.9832 27.6656C78.4429 27.5124 79.209 27.1293 79.4388 26.3632C79.592 25.9801 79.5154 25.5971 79.3622 25.214C79.209 24.9076 78.9792 24.6778 78.7493 24.5246C78.2897 24.2181 77.9832 24.2947 77.9832 24.2947C77.9832 24.3713 78.2131 24.4479 78.5961 24.7544C78.9025 25.0608 79.209 25.5971 78.9792 26.21C78.7493 26.8229 78.2131 27.1293 77.83 27.3591C77.5236 27.5124 77.2171 27.589 77.2171 27.6656Z" fill="#EB996E"/>
        <path d="M78.1352 28.9679C78.5949 29.1211 78.9013 29.734 78.5949 30.1936C78.2884 30.5767 77.5989 30.5767 77.2925 30.117C77.0627 29.6574 77.3691 29.0445 77.8288 28.9679" fill="#E8505B"/>
        <path d="M60.0551 43.4471C59.7487 46.8945 58.2165 50.1888 55.6884 52.5637C53.1602 54.9386 49.7894 56.3176 46.3419 56.3942C43.8904 56.4708 41.2856 55.8579 38.9873 56.8539C36.9955 57.6966 35.7697 59.6118 34.2375 61.144C30.6368 64.6681 24.9677 65.8939 20.2178 64.2851C18.226 63.5956 15.0083 62.14 14.6253 60.0715C14.3188 58.4627 16.3107 56.8539 17.4599 55.7047C18.609 54.5556 20.0646 53.7895 21.5968 53.1C33.4714 47.5074 46.2653 43.4471 59.4423 42.7576" fill="#2D3031"/>
    </svg>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const TextModalIllustration = defineComponent({
});

export default TextModalIllustration;
</script>

<style>

</style>