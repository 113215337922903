
import { defineComponent, ref } from 'vue';
import FacialBiometry from '@/components/FacialBiometry.vue';
import { useAlert } from '@/plugins/alert';
import { useRouter } from 'vue-router';
import vm from '@/viewModels/MainViewModel';
import UsuarioService from '@/services/UsuarioService';
const ExamBiometryPage = defineComponent({
    components: { FacialBiometry },
    setup() {
        
        const alert = useAlert();
        const { replace } = useRouter();

        const loading = ref(false);


        const check = (data: { picture: string; reset: () => void}) => {
            loading.value = true;

            const [request] = UsuarioService.Login({ emailOuCpf: vm.user!.cpf, chave: data.picture.substr(22, data.picture.length) });
            request
                .then(() => replace({ name: "exame", params: { id: vm.selectedSchedule } }))
                .catch(error => {
                    loading.value = false;
                    data.reset();
                    alert({
                        message: error
                    });
                });
        };

        return { loading, check };
    }
});

export default ExamBiometryPage;
