<template>
    <div class="w-full  px-5 pb-3 first:pt-5">

        <div class="bg-white rounded-lg cursor-pointer shadow-1 border border-transparent transition-colors duration-300"
             :class="{'border-primary': selected}"
             @click="$emit('pressed')">

            <!-- Header -->
            <div class="px-4 py-3 flex items-center border-b border-neutral-2">
                <img :src="schedule.startStatus  == 'expired' ? require('@/assets/icons/times-circle.svg') : require('@/assets/icons/check-circle.svg')"/>
                <p class="pl-3 text-lg font-medium">{{ schedule.titulo }}</p>
            </div>
            
            <!-- Conteúdo -->
            <div class="px-4 py-5" :class="[{'text-green-600 font-medium': schedule.startStatus  == 'running'}, { 'text-red-600': schedule.startStatus  == 'expired'}]">
                {{ message }} 
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import AgendamentoDTO from '@/DTOs/AgendamentoDTO';
import { computed, defineComponent } from 'vue';
import { PropType } from 'vue';
import { format } from 'date-fns';

const AgendamentoCard = defineComponent({
    props: {
        schedule: {
            type: Object as PropType<AgendamentoDTO>,
            required: true
        },
        currentDate: {
            type: Date as PropType<Date>,
            required: true
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const message = computed<string>(() => {
            switch (props.schedule.startStatus) {
                case 'expired':
                    return 'Tempo de tolerância esgotado';
                case 'running':
                    return 'Prova em andamento';
                default:
                    return `Entrada: ${format(props.schedule.startTime, 'HH:mm')}-${format(props.schedule.finishTime, 'HH:mm')}`;
            }
        });

        return { status, message };
    }
});

export default AgendamentoCard;
</script>

<style>

</style>