<template>
    <label class="flex-1 cursor-pointer px-8 hover:bg-neutral-3 transition duration-300 question" @click="saved ? null : $emit('pressed')">
        <div class="w-full h-full flex items-center py-3">
            <input type="radio" name="question" class="hidden"
                   :value="index"
                   v-model="value" :disabled="saved"/>
            <p class="text-lg flex-1 leading-5" v-html="title"></p>

            <div class="w-10 h-10 rounded-full 
                        border border-neutral-2  shadow-2 flex items-center justify-center ml-4 transition duration-300 question__option">
                <transition name="fade" mode="out-in">
                    <img src="@/assets/icons/check.svg" v-if="saved && selected"/>
                    <p v-else>{{ letter }}</p>
                </transition>
            </div>
        </div>
    </label>
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue';

const Question = defineComponent({
    props: {
        saved: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        modelValue: {
            type: Number,
        }
    },
    setup(props, context) {
        const letter = computed(() => ['A', 'B', 'C', 'D', 'E'][props.index]);
        

        const value = computed({
            get() {
                return props.modelValue;
            },
            set(value: any) {
                context.emit("update:modelValue", value);
            }
        });

        const selected = computed(() => props.modelValue == props.index);

        return { letter, value, selected };
    }
});

export default Question;
</script>

<style>
.question > div {
    border-bottom: solid #E8E8E8 1px;
}
.question:last-child > div {
    border-bottom: none;
}

.question input:checked + p {
    font-weight: bold;
}

.question input:checked ~ .question__option {
    background-color: var(--primary-color);
    color: white;
    font-weight: 700;
}
.question__option p {
    color: inherit
}
</style>