<template>
    <div class="h-screen flex items-center relative">

        <InformacoesIllustration class="absolute bottom-0 left-0 w-full"/>

        <div class="w-2/5 mx-32 max-w-screen-sm">
            <p class="text-4xl font-bold mb-6">Informações importantes antes de iniciar a prova</p>
            <p class="opacity-80 mb-6">Fique atento às instruções e deixe sua câmera ativa. Caso haja divergência, sua prova será analisada e o resultado divulgado em até 72 horas.</p>
            <ul>
                <li class="text-black mb-3"><span class="inline-block w-2 h-2 bg-primary mr-4 mb-px rounded-full"></span>Não saia do computador, validaremos a sua face durante a prova;</li>
                <li class="text-black mb-3"><span class="inline-block w-2 h-2 bg-primary mr-4 mb-px rounded-full"></span>Caso seja encontrada mais de uma face, a prova poderá ser anulada;</li>
                <li class="text-black mb-3"><span class="inline-block w-2 h-2 bg-primary mr-4 mb-px rounded-full"></span>Após confirmar uma resposta, não será possível alterá-la;</li>
                <li class="text-black mb-8"><span class="inline-block w-2 h-2 bg-primary mr-4 mb-px rounded-full"></span>Ao final do exame confirmaremos a sua biometria e o resultado será informado.</li>
            </ul>
            <button class="h-14 w-80 bg-primary rounded-lg text-white" @click="() => startExam()">
                Começar prova
            </button>
        </div>
        <ConfigureMediaModal />
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';
import InformacoesIllustration from '@/components/illustrations/InformacoesIllustration.vue';
import ConfigureMediaModal from '@/components/ConfigureMediaModal.vue';

import { useRouter } from 'vue-router';

const InformacoesPage = defineComponent({
    components: { InformacoesIllustration, ConfigureMediaModal },
    setup() {

        const router = useRouter();

        const startExam = () => {
            router.push({ name: 'biometry' });
        };
        return { startExam };
    }
});

export default InformacoesPage;
</script>

<style>
</style>