import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const insights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.VUE_APP_INSIGHTS_KEY
    }
});

const eventNames = {
    EXAM_STARTED: "exam_started",
    EXAM_FINISH: "exam_finish",
    QUESTION_ASWERED: "question_aswered",
    STREAM_LOG: "stream_log",
};

const trackError = (exception: Error) => insights.trackException({ exception: exception });
const registerCustomEvent = (eventName: string, properties?: {[key: string]: any}) => insights.trackEvent({ name: eventName, properties: properties  });
const trackPage = (name: string, uri: string, properties?: {[key: string]: any}) => insights.trackPageView({ name: name, uri: uri, properties: properties });

const trackers = {
    trackPage: trackPage,
    trackError: trackError,
    registerExamStarted: (userId: number) => registerCustomEvent(eventNames.EXAM_STARTED, { userId }),
    registerExamFinish: (userId: number) => registerCustomEvent(eventNames.EXAM_FINISH, { userId }),
    registerQuestionAswered: (userId: number) => registerCustomEvent(eventNames.QUESTION_ASWERED, { userId }),
    registerStreamLog: (streamId: string | null, action: 'initFailed' | 'tryPublish' | 'published' | 'lostConnection' | 'triedReconnect' | 'connectionFailed' | 'stoped' | 'error', info?: { [key: string]: any}) => registerCustomEvent(eventNames.STREAM_LOG, { streamId, action, ...info })
};

insights.loadAppInsights();

export default insights;
export { trackers };